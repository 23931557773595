import React, { useState, useRef, useEffect, useContext } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { NavLink, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo-img.png";
import Avatar from "@mui/material/Avatar";
import { ProfileContext } from "../../components/context/ProfileContext";
import axios from "axios";
import Swal from "sweetalert2";
import SolanIcon from "../../assets/images/sonala.png";
import sonal2Icon from "../../assets/images/sonal2.png";
import { useDispatch, useSelector } from "react-redux";
import { resetUser } from "../../store/reducers/user";

const NavBar = () => {
  const {
    userProfileImage,
    setUserProfileImage,
    adminProfileImage,
    setAdminProfileImage,
    userFirstname,
    setUserFirstname,
    userLastname,
    setUserLastname,
    adminFirstname,
    setAdminFirstname,
    adminLastname,
    setAdminLastname,
  } = useContext(ProfileContext);

  const user = useSelector((state) => state.userStore.user);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const sidebarRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token = user.auth;
  const role = user.role;

  const profileImage = role === "admin" ? adminProfileImage : userProfileImage;
  const firstname = role === "admin" ? adminFirstname : userFirstname;
  const lastname = role === "admin" ? adminLastname : userLastname;

  useEffect(() => {
    if (token) {
      const endpoint =
        role === "admin" ? "/admin/getAdminProfile" : "/users/getUserProfile";

      axios
        .get(`${process.env.REACT_APP_API_ENDPOINT_URL}${endpoint}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          if (response.data.status === 200) {
            const userData = response.data.data;
            if (role === "admin") {
              setAdminFirstname(capitalizeFirstLetter(userData.firstName));
              setAdminLastname(capitalizeFirstLetter(userData.lastName));
            } else {
              setUserFirstname(capitalizeFirstLetter(userData.firstName));
              setUserLastname(capitalizeFirstLetter(userData.lastName));
            }

            if (
              userData.profileImage &&
              typeof userData.profileImage === "string" &&
              userData.profileImage.trim() !== ""
            ) {
              try {
                const decodedData = Buffer.from(
                  userData.profileImage.replace(/^data:.*,/, ""),
                  "base64"
                );

                const uint8Array = new Uint8Array(decodedData.length);
                for (let i = 0; i < decodedData.length; i++) {
                  uint8Array[i] = decodedData[i];
                }

                const blob = new Blob([uint8Array]);
                const imgSrc = URL.createObjectURL(blob);

                if (role === "admin") {
                  setAdminProfileImage(imgSrc);
                } else {
                  setUserProfileImage(imgSrc);
                }
              } catch (err) {
                console.error("Failed to decode base64 image:", err);
              }
            }
          } else {
            Swal.fire({
              title: "Error!",
              text: `${response.data.message}`,
              icon: "error",
              confirmButtonText: "OK",
            });
          }
        })
        .catch((err) => {
          console.error("API call error:", err);
          Swal.fire({
            title: "Error!",
            text: "Something Went Wrong!",
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    }
  }, [token, user.role]);

  const handleToggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleDashBoardRoute = () => {
    if (role === "admin") {
      navigate("/admin-dashboard");
    } else {
      navigate("/user-dashboard");
    }
  };

  const logout = () => {
    dispatch(resetUser());
    // localStorage.clear();
    navigate("/");
  };

  const stringAvatar = () => {
    const firstNameInitial = firstname
      ? firstname.split(" ")[0][0]?.toUpperCase()
      : "";
    const lastNameInitial = lastname
      ? lastname.split(" ")[0][0]?.toUpperCase()
      : "";
    return {
      children: `${firstNameInitial}${lastNameInitial}`,
    };
  };

  const capitalizeFirstLetter = (string) => {
    return string
      ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
      : "";
  };

  function parseJwt(token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  useEffect(() => {
    // Check for session token expiration
    const token = user.auth;
    const tokenExpiration = parseJwt(token).exp;

    if (tokenExpiration && new Date(tokenExpiration * 1000) < new Date()) {
      // Token has expired
      dispatch(resetUser());
      navigate("/");
    }
  }, []);

  return (
    <>
      <div
        id="wrapper"
        ref={sidebarRef}
        className={isSidebarOpen ? "toggled" : ""}
      >
        <aside id="sidebar-wrapper">
          <div className="sidebar-brand">
            <img src={logo} alt="logo" />
            <div className="sidebar-brand-close">
              <i className="fa fa-close" onClick={handleToggleSidebar}></i>
            </div>
          </div>

          <div className="second-logo">
            <img src={logo} alt="logo" />
          </div>

          <div className="sidebar">
            <div className="sidebar-menu">
              <ul className="sidebar-nav">
                <li className="">
                  <NavLink
                    className={({ isActive }) => (isActive ? "active" : "")}
                    to={
                      role === "admin" ? "/admin-dashboard" : "/user-dashboard"
                    }
                  >
                    <i className="fa fa-dashboard"></i> Dashboard
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={({ isActive }) => (isActive ? "active" : "")}
                    to="/profile"
                  >
                    <i className="fa fa-user"></i> Profile
                  </NavLink>
                </li>
                {role === "user" && (
                  <li>
                    <NavLink
                      className={({ isActive }) => (isActive ? "active" : "")}
                      to="/purchase"
                    >
                      <i className="fa fa-shopping-cart"></i> Purchase
                    </NavLink>
                  </li>
                )}
                {role === "admin" && (
                  <li>
                    <NavLink
                      className={({ isActive }) => (isActive ? "active" : "")}
                      to="/admin-create-liquidity"
                    >
                      <i className="fa fa-plus"></i> Token Creation
                    </NavLink>
                  </li>
                )}
                {/* {role === "admin" && (
                  <li>
                    <NavLink
                      className={({ isActive }) => (isActive ? "active" : "")}
                      to="/admin-lfc-deposit"
                    >
                      <i className="fa fa-sack-dollar"></i> $LAL/SOL Deposit
                    </NavLink>
                  </li>
                )}
                {role === "admin" && (
                  <li>
                    <NavLink
                      className={({ isActive }) => (isActive ? "active" : "")}
                      to="/admin-lfc-withdraw"
                    >
                      <i className="fa fa-money-bill-transfer"></i> $LAL/SOL
                      Withdraw
                    </NavLink>
                  </li>
                )} */}
                {role === "admin" && (
                  <li>
                    <NavLink
                      className={({ isActive }) => (isActive ? "active" : "")}
                      to="/admin-transaction-history"
                    >
                      <div className="image-container">
                        <img
                          src={SolanIcon}
                          width="28"
                          height="28"
                          className="main-image"
                        />
                        <img
                          src={sonal2Icon}
                          width="28"
                          height="28"
                          alt="Image 2"
                          className="hover-image"
                        />
                        <span className="text">Txn History</span>
                      </div>
                    </NavLink>
                  </li>
                )}
                {/* {role === "admin" && (
                  <li>
                    <NavLink
                      className={({ isActive }) => (isActive ? "active" : "")}
                      to="/admin-deposit-withdraw-history"
                    >
                      <i className="fa fa-history"></i> $LAL/SOL Txn History
                    </NavLink>
                  </li>
                )} */}
                {role === "admin" && (
                  <li>
                    <NavLink
                      className={({ isActive }) => (isActive ? "active" : "")}
                      to="/admin-settings"
                    >
                      <i className="fa fa-cog"></i> Settings
                    </NavLink>
                  </li>
                )}
                {role === "user" && (
                  <li>
                    <NavLink
                      className={({ isActive }) => (isActive ? "active" : "")}
                      to="/community"
                    >
                      <i className="fa fa-users"></i> Community
                    </NavLink>
                  </li>
                )}
                {role === "user" && (
                  <li>
                    <NavLink
                      className={({ isActive }) => (isActive ? "active" : "")}
                      to="/roadmap"
                    >
                      <i className="fa fa-road"></i> Road Map
                    </NavLink>
                  </li>
                )}
                {role === "user" && (
                  <li>
                    <NavLink
                      className={({ isActive }) => (isActive ? "active" : "")}
                      to="/transaction-history"
                    >
                      <div className="image-container">
                        <img
                          src={SolanIcon}
                          width="28"
                          height="28"
                          className="main-image"
                        />
                        <img
                          src={sonal2Icon}
                          width="28"
                          height="28"
                          alt="Image 2"
                          className="hover-image"
                        />
                        <span className="text">Txn History</span>
                      </div>
                    </NavLink>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </aside>

        <div id="navbar-wrapper">
          <nav className="navbar">
            <div className="container-fluid">
              <div className="navbar-header">
                <button
                  className="navbar-button"
                  id="add-toggle"
                  onClick={handleToggleSidebar}
                >
                  <i className="fa fa-bars"></i>
                </button>
              </div>

              <div className="navbar-profile">
                <div className="profile-dropdown profile">
                  <Dropdown>
                    <Dropdown.Toggle variant="" id="dropdown-basic">
                      <h6>
                        {` ${capitalizeFirstLetter(
                          firstname
                        )} ${capitalizeFirstLetter(lastname)}`}
                      </h6>
                      {profileImage ? (
                        <img
                          src={profileImage}
                          alt="Profile"
                          className="profile-image-top"
                        />
                      ) : (
                        <Avatar alt="User" {...stringAvatar()} />
                      )}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={handleDashBoardRoute}>
                        <i className="fa fa-dashboard"></i> Dashboard
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => navigate("/profile")}>
                        <i className="fa fa-user"></i> Profile
                      </Dropdown.Item>
                      <Dropdown.Item onClick={logout}>
                        <i className="fa fa-sign-out"></i> Log Out
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};

export default NavBar;
