import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import ForgotPassword from "../views/auth/ForgotPassword";
import LogInForm from "../views/auth/LogInForm";
import SignUpForm from "../views/auth/SignUpForm";
import AdminDashboard from "../views/dashboard/admin/AdminDashboard";
import PrivateComponent from "../views/auth/PrivateComponent";
import VerifyAccount from "../views/auth/VerifyAccount";
import ResetPassword from "../views/auth/ResetPassword";
import UserDashboard from "../views/dashboard/user/UserDashboard";
import Profile from "../views/profile/Profile";
import AdminLFCDeposit from "../views/dashboard/admin/AdminLFCDeposit";
import RoadMap from "../views/dashboard/user/RoadMap";
import Community from "../views/dashboard/user/Community";
import Main from "../views/promotional/Main";
import Purchase from "../views/dashboard/user/Purchase";
import TransactionHistory from "../views/dashboard/user/TransactionHistory";
import AdminTransactionHistory from "../views/dashboard/admin/AdminTransactionHistory";
import AdminWithdraw from "../views/dashboard/admin/AdminWithdraw";
import AdminDepositWithdrawHistory from "../views/dashboard/admin/AdminDepositWithdrawHistory";
import AdminToken from "../views/dashboard/admin/token/AdminToken";
import AdminSettings from "../views/dashboard/admin/settings/AdminSettings";

const RouterPage = () => {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/login" element={<LogInForm />} />
          <Route path="/sign-up" element={<SignUpForm />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/verify-account/:token" element={<VerifyAccount />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="/launchparty" element={<Main />} />
          {/* <Route path="*" element={<Navigate to="/launchparty" replace />} /> */}
          <Route element={<PrivateComponent />}>
            <Route path="/admin-dashboard" element={<AdminDashboard />} />
            <Route path="/user-dashboard" element={<UserDashboard />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/admin-lfc-deposit" element={<AdminLFCDeposit />} />
            <Route path="/admin-lfc-withdraw" element={<AdminWithdraw />} />
            <Route path="/roadmap" element={<RoadMap />} />
            <Route path="/community" element={<Community />} />
            <Route path="/purchase" element={<Purchase />} />
            <Route
              path="/transaction-history"
              element={<TransactionHistory />}
            />
            <Route
              path="/admin-transaction-history"
              element={<AdminTransactionHistory />}
            />
            <Route
              path="/admin-deposit-withdraw-history"
              element={<AdminDepositWithdrawHistory />}
            />
            <Route path="/admin-create-liquidity" element={<AdminToken />} />
            <Route path="/admin-settings" element={<AdminSettings />} />
          </Route>
        </Routes>
      </div>
    </Router>
  );
};

export default RouterPage;
