import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import NavBar from "../../components/navbar/NavBar";
import Swal from "sweetalert2";
import { ProfileContext } from "../../components/context/ProfileContext";
import { useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import {
  validateAddress,
  validateFirstName,
  validateLastName,
  validateNoNumbersOrSpecialChars,
} from "../../utils/validation";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, parseISO, isValid } from "date-fns";

const Profile = () => {
  const {
    setUserProfileImage,
    setAdminProfileImage,
    setUserFirstname,
    setUserLastname,
    setAdminFirstname,
    setAdminLastname,
  } = useContext(ProfileContext);
  const [firstname, setFirstNameLocal] = useState("");
  const [lastname, setLastNameLocal] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [walletAddress, setWalletAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [zip, setZip] = useState("");
  const [phone, setPhone] = useState("");
  const [dob, setDOB] = useState(null);
  const [description, setDescription] = useState("");
  const [role, setRole] = useState("");
  const [profileImage, setProfileImageLocal] = useState(null);
  const [imageBase64, setImageBase64] = useState(null);
  const [errorFirstName, setErrorFirstName] = useState("");
  const [errorLastName, setErrorLastName] = useState("");
  const [errorPhone, setErrorPhone] = useState("");
  const [errorAddress, setErrorAddress] = useState("");
  const [errorCity, setErrorCity] = useState("");
  const [errorState, setErrorState] = useState("");
  const [errorCountry, setErrorCountry] = useState("");
  const [errorZip, setErrorZip] = useState("");
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsopen] = useState(false);

  const user = useSelector((state) => state.userStore.user);
  const token = user.auth;

  useEffect(() => {
    if (token) {
      const role = user.role;
      setRole(role);

      const endpoint =
        role === "admin" ? "/admin/getAdminProfile" : "/users/getUserProfile";

      axios
        .get(`${process.env.REACT_APP_API_ENDPOINT_URL}${endpoint}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          if (response.data.status === 200) {
            const userData = response.data.data;
            setFirstNameLocal(capitalizeFirstLetter(userData.firstName || ""));
            setLastNameLocal(capitalizeFirstLetter(userData.lastName || ""));
            setEmail(userData.email || "");
            setAddress(userData.address || "");
            setCity(userData.city || "");
            setState(userData.state || "");
            setZip(userData.zip || "");
            setPhone(userData.phone || "");
            setCountry(userData.country || "");

            const dobDate = parseISO(userData.dob ?? "");
            if (isValid(dobDate)) {
              setDOB(dobDate);
            }
            setDescription(userData.description || "");
            setWalletAddress(userData.walletAddress || "");

            if (
              userData.profileImage &&
              typeof userData.profileImage === "string" &&
              userData.profileImage.trim() !== ""
            ) {
              try {
                const decodedData = Buffer.from(
                  userData.profileImage.replace(/^data:.*,/, ""),
                  "base64"
                );

                const uint8Array = new Uint8Array(decodedData.length);
                for (let i = 0; i < decodedData.length; i++) {
                  uint8Array[i] = decodedData[i];
                }

                const blob = new Blob([uint8Array]);
                const imgSrc = URL.createObjectURL(blob);
                setImageBase64(imgSrc);
              } catch (err) {
                console.error("Failed to decode base64 image:", err);
              }
            }
          } else {
            Swal.fire({
              title: "Error!",
              text: `${response.data.message}`,
              icon: "error",
              confirmButtonText: "OK",
            });
          }
        })
        .catch((err) => {
          console.error("API call error:", err);
          Swal.fire({
            title: "Error!",
            text: "Something Went Wrong!",
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    }
  }, [token, user.role]);

  function formatPhone(value) {
    let format = "";
    var numbers = value.replace(/\D/g, ""),
      char = { 0: "+1 (", 3: ") ", 6: " - " };
    if (numbers[0] === "1") numbers = numbers.slice(1);
    format = "";
    for (var i = 0; i < numbers.length; i++) {
      format += (char[i] || "") + numbers[i];
    }
    return format;
  }

  const handlePhoneChange = (e) => {
    let value = e.target.value.replace(/\D/g, "").substring(0, 11);
    let formattedPhone = formatPhone(value);
    setPhone(formattedPhone);
    const cleanedPhone = formattedPhone.replace(/\D/g, "");
    if (cleanedPhone.length === 11) {
      setErrorPhone("");
    } else if (cleanedPhone.length > 1 && cleanedPhone.length < 11) {
      setErrorPhone("Incomplete phone number");
    }
  };

  const handleUpdateProfile = (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append("firstname", firstname ? firstname : "");
    formData.append("lastname", lastname ? lastname : "");
    formData.append("address", address ? address : "");
    formData.append("city", city ? city : "");
    formData.append("state", state ? state : "");
    formData.append("zip", zip ? zip : "");
    formData.append("phone", phone ? phone : "");
    formData.append(
      "dob",
      dob && isValid(dob) ? format(dob, "yyyy-MM-dd") : ""
    );
    formData.append("country", country ? country : "");
    formData.append("description", description ? description : "");
    formData.append("walletAddress", walletAddress ? walletAddress : "");

    if (profileImage) {
      formData.append("image", profileImage);
    }

    if (firstname) {
      if (!validateFirstName(firstname)) {
        setErrorFirstName(
          firstname.length > 0 && firstname.length < 3
            ? "First Name must be at least 3 characters long"
            : "Please enter first name"
        );
        setLoading(false);
        return;
      }
    }

    if (lastname) {
      if (!validateLastName(lastname)) {
        setErrorLastName(
          lastname.length > 0 && lastname.length < 3
            ? "Last Name must be at least 3 characters long"
            : "Please enter last name"
        );
        setLoading(false);
        return;
      }
    }

    if (address) {
      if (!validateAddress(address)) {
        setErrorAddress("Invalid address format.");
        setLoading(false);
        return;
      }
    }

    const endpoint =
      role === "admin"
        ? "/admin/updateAdminProfile"
        : "/users/updateUserProfile";

    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT_URL}${endpoint}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.data.status === 200) {
          Swal.fire({
            title: "Success!",
            text: "Your profile is successfully updated",
            icon: "success",
            confirmButtonText: "OK",
          });
          if (role === "admin") {
            setAdminProfileImage(imageBase64);
            setAdminFirstname(firstname);
            setAdminLastname(lastname);
          } else {
            setUserProfileImage(imageBase64);
            setUserFirstname(firstname);
            setUserLastname(lastname);
          }
        } else {
          Swal.fire({
            title: "Error!",
            text: `${response.data.message}`,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      })
      .catch(() => {
        Swal.fire({
          title: "Error!",
          text: "Something Went Wrong! Please try again",
          icon: "error",
          confirmButtonText: "OK",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setProfileImageLocal(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setImageBase64(reader.result);
      if (role === "admin") {
        setAdminProfileImage(reader.result);
      } else {
        setUserProfileImage(reader.result);
      }
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const stringAvatar = () => {
    const firstNameInitial = firstname
      ? firstname.split(" ")[0][0]?.toUpperCase()
      : "";
    const lastNameInitial = lastname
      ? lastname.split(" ")[0][0]?.toUpperCase()
      : "";
    return {
      children: `${firstNameInitial}${lastNameInitial}`,
    };
  };

  const imageButtonText =
    profileImage || imageBase64 ? "Edit Profile Image" : "Upload Profile Image";

  return (
    <>
      <NavBar />
      <div className={`sidebar-sec ${isOpen === true ? "active" : ""}`}>
        <section id="content-wrapper">
          <h4 className="my-dash">My Profile</h4>
          <div className="container-dashboard-right">
            <div className="page-head-name">
              <p className="content-title">Profile</p>
            </div>
            <div className="container-fluid">
              <form onSubmit={handleUpdateProfile}>
                <div className="profile-main-wrapper">
                  <div className="profile-form-wrapper">
                    <div className="row">
                      <div className="col-lg-3 col-sm-12">
                        <div className="profile_image-and-detail">
                          <div className="profile-img-header">
                            <div className="avatar-upload">
                              <div className="avatar-edit">
                                <input
                                  type="file"
                                  id="imageUpload"
                                  accept="image/*"
                                  onChange={(e) => handleFileChange(e)}
                                  className="profile-image-set"
                                />
                              </div>
                              <div className="avatar-preview">
                                {imageBase64 && (
                                  <img
                                    src={imageBase64}
                                    alt="User"
                                    className="profile-image-preview"
                                  />
                                )}
                                {!imageBase64 && (
                                  <Avatar alt="User" {...stringAvatar()} />
                                )}
                              </div>
                            </div>
                            <div className="profile-btn">
                              <button
                                type="button"
                                id="uploadButton"
                                className="btn btn-light"
                                onClick={() =>
                                  document.getElementById("imageUpload").click()
                                }
                              >
                                {imageButtonText}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-9 col-sm-12">
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                              <label htmlFor="" className="form-label">
                                First Name
                              </label>
                              <input
                                type="text"
                                className="form-control Profile-input mt-1"
                                placeholder="Enter firstname"
                                value={firstname || ""}
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  if (
                                    validateNoNumbersOrSpecialChars(inputValue)
                                  ) {
                                    const capitalizedValue =
                                      capitalizeFirstLetter(inputValue);
                                    setFirstNameLocal(capitalizedValue);
                                    setErrorFirstName("");
                                  } else {
                                    setErrorFirstName(
                                      "Only alphabet characters are allowed."
                                    );
                                  }
                                }}
                                maxLength={16}
                              />
                            </div>
                            {errorFirstName && (
                              <p className="text-warning">{errorFirstName}</p>
                            )}
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                              <label htmlFor="" className="form-label">
                                Last Name
                              </label>
                              <input
                                type="text"
                                className="form-control Profile-input mt-1"
                                placeholder="Enter lastname"
                                value={lastname || ""}
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  if (
                                    validateNoNumbersOrSpecialChars(inputValue)
                                  ) {
                                    const capitalizedValue =
                                      capitalizeFirstLetter(inputValue);
                                    setLastNameLocal(capitalizedValue);
                                    setErrorLastName("");
                                  } else {
                                    setErrorLastName(
                                      "Only alphabet characters are allowed."
                                    );
                                  }
                                }}
                                maxLength={16}
                              />
                            </div>
                            {errorLastName && (
                              <p className="text-warning">{errorLastName}</p>
                            )}
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                              <label htmlFor="" className="form-label">
                                Email
                              </label>
                              <input
                                type="text"
                                className="form-control Profile-input mt-1"
                                placeholder="Enter email"
                                value={email || ""}
                                onChange={(e) => setEmail(e.target.value)}
                                disabled
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                              <label htmlFor="" className="form-label">
                                Phone
                              </label>
                              <input
                                type="tel"
                                className="form-control"
                                id="phone"
                                name="phone"
                                placeholder="Phone"
                                value={phone || ""}
                                onChange={handlePhoneChange}
                                onBlur={() => {
                                  if (phone === "+1 ") setPhone("");
                                }}
                              />
                            </div>
                            {errorPhone && (
                              <p className="text-warning">{errorPhone}</p>
                            )}
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                              <label
                                htmlFor=""
                                className="form-label dob-label"
                              >
                                DOB
                              </label>
                              <DatePicker
                                selected={dob}
                                onChange={(date) => setDOB(date)}
                                dateFormat="MM-dd-yyyy"
                                maxDate={new Date()}
                                className="form-control Profile-input mt-1 dob"
                                placeholderText="MM-DD-YYYY"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                              <label htmlFor="" className="form-label">
                                Wallet Address
                              </label>
                              <input
                                type="text"
                                className="form-control Profile-input mt-1"
                                placeholder="Enter Wallet Address"
                                value={walletAddress || ""}
                                onChange={(e) =>
                                  setWalletAddress(e.target.value)
                                }
                                maxLength={65}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                              <label htmlFor="" className="form-label">
                                Address
                              </label>
                              <input
                                type="text"
                                className="form-control Profile-input mt-1"
                                placeholder="Enter address"
                                value={address || ""}
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  if (!inputValue.startsWith(" ")) {
                                    const capitalizedValue =
                                      capitalizeFirstLetter(inputValue);
                                    setAddress(capitalizedValue);
                                    setErrorAddress("");
                                  } else {
                                    setErrorAddress(
                                      "Address cannot start with a space."
                                    );
                                  }
                                }}
                                maxLength={20}
                              />
                            </div>
                            {errorAddress && (
                              <p className="text-warning">{errorAddress}</p>
                            )}
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                              <label htmlFor="" className="form-label">
                                City
                              </label>
                              <input
                                type="text"
                                className="form-control Profile-input mt-1"
                                placeholder="Enter City"
                                value={city || ""}
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  if (
                                    validateNoNumbersOrSpecialChars(inputValue)
                                  ) {
                                    const capitalizedValue =
                                      capitalizeFirstLetter(inputValue);
                                    setCity(capitalizedValue);
                                    setErrorCity("");
                                  } else {
                                    setErrorCity(
                                      "Only alphabet characters are allowed."
                                    );
                                  }
                                }}
                                maxLength={32}
                              />
                            </div>
                            {errorCity && (
                              <p className="text-warning">{errorCity}</p>
                            )}
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                              <label htmlFor="" className="form-label">
                                State
                              </label>
                              <input
                                type="text"
                                className="form-control Profile-input mt-1"
                                placeholder="Enter state"
                                value={state || ""}
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  if (
                                    validateNoNumbersOrSpecialChars(inputValue)
                                  ) {
                                    const capitalizedValue =
                                      capitalizeFirstLetter(inputValue);
                                    setState(capitalizedValue);
                                    setErrorState("");
                                  } else {
                                    setErrorState(
                                      "Only alphabet characters are allowed."
                                    );
                                  }
                                }}
                                maxLength={32}
                              />
                            </div>
                            {errorState && (
                              <p className="text-warning">{errorState}</p>
                            )}
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                              <label htmlFor="" className="form-label">
                                Country
                              </label>
                              <input
                                type="text"
                                className="form-control Profile-input mt-1"
                                placeholder="Enter country"
                                value={country || ""}
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  if (
                                    validateNoNumbersOrSpecialChars(inputValue)
                                  ) {
                                    const capitalizedValue =
                                      capitalizeFirstLetter(inputValue);
                                    setCountry(capitalizedValue);
                                    setErrorCountry("");
                                  } else {
                                    setErrorCountry(
                                      "Only alphabet characters are allowed."
                                    );
                                  }
                                }}
                                maxLength={32}
                              />
                            </div>
                            {errorCountry && (
                              <p className="text-warning">{errorCountry}</p>
                            )}
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                              <label htmlFor="" className="form-label">
                                ZIP Code
                              </label>
                              <input
                                type="number"
                                className="form-control Profile-input mt-1"
                                placeholder="Enter zip"
                                value={zip || ""}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (value.length <= 5) {
                                    setZip(value);
                                    if (value.length === 5) {
                                      setErrorZip("");
                                    } else {
                                      setErrorZip(
                                        "Zip Code must be five digits long."
                                      );
                                    }
                                  }
                                }}
                              />
                            </div>
                            {errorZip && (
                              <p className="text-warning">{errorZip}</p>
                            )}
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group">
                              <label htmlFor="" className="form-label">
                                Description
                              </label>
                              <textarea
                                className="form-control mb-3"
                                id=""
                                rows="3"
                                value={description || ""}
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  const capitalizedValue =
                                    capitalizeFirstLetter(inputValue);
                                  setDescription(capitalizedValue);
                                }}
                                maxLength={3000}
                              ></textarea>
                            </div>
                          </div>
                          <div className="profile-btn">
                            <button
                              type="submit"
                              className="btn"
                              disabled={loading}
                            >
                              {loading ? (
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              ) : (
                                "Update Profile"
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Profile;
