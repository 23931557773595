import React, { useState } from "react";
import NavBar from "../../../components/navbar/NavBar";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import SolPurchaseHistory from "./SolPurchaseHistory";
// import LFCPurchaseHistory from "./LFCPurchaseHistory";
// import LFCToSOLPurchaseHistory from "./LFCToSOLPurchaseHistory";
import LALPurchaseHistory from "./LALPurchaseHistory";

const TransactionHistory = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <NavBar />
      <div className={`sidebar-sec ${isOpen === true ? "active" : ""}`}>
        <section id="content-wrapper">
          <h4 className="my-dash">Review Your Purchases</h4>
          <div className="trans-tab">
            <Tabs
              defaultActiveKey="sol"
              id="uncontrolled-tab-example"
              className=""
            >
              <Tab eventKey="sol" title="SOL Txn History">
                <SolPurchaseHistory />
              </Tab>

              {/* <Tab eventKey="sol-to-lal" title="SOL to $LAL Txn History">
                <LFCPurchaseHistory />
              </Tab>
              <Tab eventKey="lal-to-sol" title="$LAL to SOL Txn History">
                <LFCToSOLPurchaseHistory />
              </Tab> */}
              <Tab eventKey="lal" title="$LAL Txn History">
                <LALPurchaseHistory />
              </Tab>
            </Tabs>
          </div>
        </section>
      </div>
    </>
  );
};

export default TransactionHistory;
