import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { DateRangePicker } from "rsuite";
import { handleDownloadExcel } from "../../../../utils/excelHelper";
import Tooltip from "@mui/material/Tooltip";
import { useSelector } from "react-redux";
import useWallet from "../../../../utils/hooks";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AdminTokenHistory = () => {
  const { copyToClipboard, sortField, sortOrder, sortHistory, onSort } =
    useWallet();
  const navigate = useNavigate();
  const [history, setHistory] = useState([]);
  const [filters, setFilters] = useState({
    createdAt: "",
    deploymentwalletAddress: "",
    tokenContractAddress: "",
    startDate: null,
    endDate: null,
  });
  const user = useSelector((state) => state.userStore.user);

  const normalizeStartDate = (date) => {
    const normalizedDate = new Date(date);
    normalizedDate.setHours(0, 0, 0, 0);
    return normalizedDate;
  };

  const normalizeEndDate = (date) => {
    const normalizedDate = new Date(date);
    normalizedDate.setHours(23, 59, 59, 999);
    return normalizedDate;
  };

  useEffect(() => {
    const token = user.auth;

    const fetchLFCPurchaseHistory = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_ENDPOINT_URL}/deployment/getTokenDepolyment`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setHistory(response.data.data);
      } catch (error) {
        console.error("Failed to fetch token address:", error);
      }
    };

    fetchLFCPurchaseHistory();
  }, [navigate]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
  };

  const onFilterChange = (e, field) => {
    const value = e.target.value;
    setFilters((prevFilters) => ({ ...prevFilters, [field]: value }));
  };

  const filterHistory = () => {
    return history.filter((item) => {
      const transactionDate = new Date(item.createdAt);
      let dateMatch = true;

      if (filters.startDate && filters.endDate) {
        const startDate = normalizeStartDate(filters.startDate);
        const endDate = normalizeEndDate(filters.endDate);
        dateMatch = transactionDate >= startDate && transactionDate <= endDate;
      }

      const walletMatch = item.deploymentwalletAddress
        .toLowerCase()
        .includes(filters.deploymentwalletAddress.toLowerCase());

      const tokenAddress = item.tokenContractAddress
        .toLowerCase()
        .includes(filters.tokenContractAddress.toLowerCase());
      return dateMatch && walletMatch && tokenAddress;
    });
  };

  const onDateRangeChange = (range) => {
    if (range) {
      setFilters({
        ...filters,
        startDate: range[0],
        endDate: range[1],
      });
    } else {
      setFilters({
        ...filters,
        startDate: null,
        endDate: null,
      });
    }
  };

  const filteredHistory = filterHistory();
  const sortedHistory = sortHistory(filteredHistory);

  const renderHeader = () => {
    return (
      <div className="table-search">
        <div className="row">
          <div className="col-lg-2 col-sm-12">
            <div className="table-search-inner">
              <DateRangePicker
                placeholder="Select Date Range"
                onChange={onDateRangeChange}
              />
            </div>
          </div>
          <div className="col-lg-2 col-sm-12">
            <div className="table-search-inner">
              <InputGroup className="mb-3">
                <Form.Control
                  value={filters.deploymentwalletAddress}
                  onChange={(e) => onFilterChange(e, "deploymentwalletAddress")}
                  placeholder="Search by Wallet Address"
                />
                <InputGroup.Text id="basic-addon2">
                  <i className="fa fa-search"></i>
                </InputGroup.Text>
              </InputGroup>
            </div>
          </div>
          <div className="col-lg-2 col-sm-12">
            <div className="table-search-inner">
              <InputGroup className="mb-3">
                <Form.Control
                  value={filters.tokenContractAddress}
                  onChange={(e) => onFilterChange(e, "tokenContractAddress")}
                  placeholder="Search by Token Address"
                />
                <InputGroup.Text id="basic-addon2">
                  <i className="fa fa-search"></i>
                </InputGroup.Text>
              </InputGroup>
            </div>
          </div>
          <div className="col">
            <div className="table-download">
              <button
                className="btn"
                onClick={() =>
                  handleDownloadExcel(filteredHistory, "Token_History")
                }
              >
                <Tooltip title="Download Token History" arrow>
                  <i className="fa fa-file-excel"></i>
                </Tooltip>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="table-box sol-purchase">
        <DataTable
          value={sortedHistory}
          paginator
          rows={10}
          dataKey="_id"
          responsiveLayout="scroll"
          header={renderHeader()}
          onSort={onSort}
          sortField={sortField}
          sortOrder={sortOrder}
        >
          <Column
            field="serialNumber"
            header="S.No"
            body={(_, { rowIndex }) => {
              const totalRecords = sortedHistory.length;
              const globalIndex = rowIndex + 1;

              if (sortField === "serialNumber" && sortOrder === -1) {
                return totalRecords - rowIndex;
              }
              return globalIndex;
            }}
            sortable
          />
          <Column
            field="createdAt"
            header="Date of Token Create"
            body={(rowData) => formatDate(rowData.createdAt)}
            sortable
          />
          <Column
            field="deploymentwalletAddress"
            header="Deployment Wallet Address"
            sortable
            body={(rowData) => (
              <div className="copy-wallet">
                <span>
                  {rowData.deploymentwalletAddress
                    ? `${rowData.deploymentwalletAddress.substring(
                        0,
                        4
                      )}...${rowData.deploymentwalletAddress.slice(-4)}`
                    : ""}
                </span>
                <Tooltip title="Copy Wallet Address" arrow>
                  <FontAwesomeIcon
                    icon={faCopy}
                    className="copy-wallet-icon"
                    onClick={() =>
                      copyToClipboard(rowData.deploymentwalletAddress)
                    }
                  />
                </Tooltip>
              </div>
            )}
          />
          <Column
            field="tokenContractAddress"
            header="Token Address"
            sortable
            body={(rowData) => (
              <div className="copy-wallet">
                <span>
                  {rowData.tokenContractAddress
                    ? `${rowData.tokenContractAddress.substring(
                        0,
                        4
                      )}...${rowData.tokenContractAddress.slice(-4)}`
                    : ""}
                </span>
                <Tooltip title="Copy Token Address" arrow>
                  <FontAwesomeIcon
                    icon={faCopy}
                    className="copy-wallet-icon"
                    onClick={() =>
                      copyToClipboard(rowData.tokenContractAddress)
                    }
                  />
                </Tooltip>
              </div>
            )}
          />
        </DataTable>
      </div>
    </>
  );
};

export default AdminTokenHistory;
