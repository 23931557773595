import { Keypair } from "@solana/web3.js";
import { raw } from "../constants/StateRawKey";
import { Connection } from "@solana/web3.js";
import {
  AccountLayout,
  TOKEN_PROGRAM_ID,
  ASSOCIATED_TOKEN_PROGRAM_ID,
  getAssociatedTokenAddress,
  NATIVE_MINT,
} from "@solana/spl-token";
import BigNumber from "bignumber.js";
import {
  TOKEN_A_DECIMALS,
  TOKEN_B_DECIMALS,
  LFC_PAIR,
  swapAuthority,
} from "../constants/constants";
import { InputGroup } from "react-bootstrap";
const rpc = process.env.REACT_APP_RPC_URL;

const connection = new Connection(rpc);

function getKeypairFromU8Array(secretKeyArray) {
  try {
    // Ensure the array is a Uint8Array
    const secretKeyUint8Array = new Uint8Array(secretKeyArray);

    // Create and return the Keypair from the secret key
    const keyPair = Keypair.fromSecretKey(secretKeyUint8Array);
    return keyPair;
  } catch (error) {
    console.error("Error loading keypair:", error.message);
    return 500;
  }
}

const value = getKeypairFromU8Array(raw);

// Create the Keypair from the secret key
export const tokenSwapStateAccount = value;

export function loadKeypairfromRAW(raw) {
  const keypair = Keypair.fromSecretKey(new Uint8Array(raw));
  return keypair;
}

const checkedDiv = (lhs, rhs) => {
  try {
    let quotient = lhs / rhs;

    if (quotient == 0n) {
      return null;
    }

    let rem = lhs % rhs;
    if (rem > 0n) {
      quotient = quotient + BigNumber(1);

      rhs = lhs / quotient;
      rem = lhs % quotient;
      if (rem > 0) {
        rhs = rhs + BigNumber(1);
      }
    }

    return quotient;
  } catch (err) {
    console.log("something went wrong", err);
    return null;
  }
};

const fetchTokenBalances = async () => {
  try {
    const lfcAccount = await getAssociatedAddress(LFC_PAIR, swapAuthority);

    const wSolAccount = await getAssociatedAddress(NATIVE_MINT, swapAuthority);

    const lfcAccountInfo = await connection.getAccountInfo(lfcAccount);
    const wSolAccountInfo = await connection.getAccountInfo(wSolAccount);

    if (lfcAccountInfo && wSolAccountInfo) {
      const lfcData = AccountLayout.decode(lfcAccountInfo.data);
      const wSolData = AccountLayout.decode(wSolAccountInfo.data);
      return [BigNumber(lfcData.amount), BigNumber(wSolData.amount)];
    }
  } catch (error) {
    console.error("Error fetching token balances:", error);
  }
};

const fetchWalletBalance = async (wallet) => {
  const wSolAccount = await getAssociatedAddress(NATIVE_MINT, wallet);

  const accountInfo = await connection.getAccountInfo(wSolAccount);

  if (accountInfo) {
    const userBalance = accountInfo.lamports;

    return BigNumber(userBalance);
  }
  return BigNumber(0);
};

export const calculateExchangeRate = async (inputAmount, from, wallet) => {
  const [lfcBalance, wSolBalance] = await fetchTokenBalances();

  if (lfcBalance > 0n && wSolBalance > 0n) {
    if (from === "sol") {
      const inputAmountScaled = inputAmount.times(BigNumber(TOKEN_B_DECIMALS)); // Scale up for precision
      const constant = lfcBalance.times(wSolBalance);
      const fee = inputAmountScaled.times(2).div(1000);

      const inputToSend = inputAmountScaled.minus(fee);

      const totalInput = wSolBalance.plus(inputToSend);

      const newYBalance = checkedDiv(constant, totalInput);

      const outputAmount = lfcBalance.minus(newYBalance);

      const burn_fee = fee.times(5).div(1000);
      const burnEquivalent = wSolBalance.plus(burn_fee);
      const newBurnBalance = constant.div(burnEquivalent);
      const burnAmount = lfcBalance.minus(newBurnBalance);

      const outputAmountAfterFee = outputAmount;

      const rate = outputAmountAfterFee.div(BigNumber(TOKEN_A_DECIMALS)); // Scale back down

      return [rate, fee, burnAmount, outputAmount];
    } else if (from === "lfc") {
      const closingAmount = await fetchWalletBalance(wallet);

      console.log("The closing amount is: ", closingAmount.toFixed(9));

      const inputAmountScaled = BigNumber(inputAmount).times(
        BigNumber(TOKEN_A_DECIMALS)
      );
      const constant = lfcBalance.times(wSolBalance);
      const fee = inputAmountScaled.times(2).div(1000);

      const inputToSend = inputAmountScaled.minus(fee);

      const totalInput = lfcBalance.plus(inputToSend);

      const newYBalance = checkedDiv(constant, totalInput);

      const outputAmount = wSolBalance.minus(newYBalance);

      const burn_fee = fee.times(5).div(1000);
      const outputAmountAfterFee = outputAmount;
      const finalAmount = outputAmountAfterFee.plus(closingAmount);
      const rate = finalAmount.div(BigNumber(TOKEN_B_DECIMALS));

      return [rate, fee, burn_fee, outputAmount];
    }
  }
};

export const getAssociatedAddress = async (mint, owner) => {
  let tokenAccountAddress = await getAssociatedTokenAddress(
    mint,
    owner,
    true,
    TOKEN_PROGRAM_ID,
    ASSOCIATED_TOKEN_PROGRAM_ID
  );

  return tokenAccountAddress;
};
