import React, { useState, useEffect } from "react";
import Spinner from "react-bootstrap/Spinner";

const SolanaPrice = () => {
  const [price, setPrice] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const socket = new WebSocket(
      "wss://fstream.binance.com/stream?streams=solusdt@ticker"
    );

    socket.onopen = () => {
      // console.log("WebSocket connection established");
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      setLoading(false);
      setPrice(data.data.c);
    };

    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    socket.onclose = () => {
      // console.log("WebSocket connection closed");
    };

    return () => {
      socket.close();
    };
  }, []);

  if (loading) {
    return (
      <div className="lf-spinner">
        <Spinner animation="border" variant="dark" size="sm" />
      </div>
    );
  }

  return <p className="solana-price">${price}</p>;
};

export default SolanaPrice;
