import React, { useState, useEffect } from "react";
import NavBar from "../../../components/navbar/NavBar";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import Swal from "sweetalert2";
import Tooltip from "@mui/material/Tooltip";
import phantomIcon from "../../../assets/images/phantom.png";
import axios from "axios";
import { Transaction, LAMPORTS_PER_SOL } from "@solana/web3.js";
import { TokenSwap } from "@solana/spl-token-swap";
import {
  getAssociatedTokenAddress,
  NATIVE_MINT,
  TOKEN_PROGRAM_ID,
  getAccount,
  createAssociatedTokenAccountInstruction,
  createCloseAccountInstruction,
} from "@solana/spl-token";
import {
  TOKEN_SWAP_PROGRAM_ID,
  LFC_PAIR,
  LP_TOKEN,
  fee_owner,
  TOKEN_A_DECIMALS,
  tokenSwap,
  connection,
  swapAuthority,
} from "../../../constants/constants";
import BigNumber from "bignumber.js";
import useWallet from "../../../utils/hooks";
import { useSelector } from "react-redux";

const AdminWithdraw = () => {
  const {
    isOpen,
    connected,
    walletAddress,
    balance,
    isConnected,
    connectWallet,
    disconnectWallet,
    handleRightToggle,
    copyAccountToClipboard,
  } = useWallet();

  const [lfcAMountToWithdraw, setLfcAmountToWithdraw] = useState("");
  const [solAmountToWithdraw, setSolAmountToWithdraw] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const user = useSelector((state) => state.userStore.user);
  const token = user.auth;

  const withdrawSingleLiquidity = async (isSol) => {
    console.log("Withdrawing");
    const _wallet = isConnected;
    if (
      (lfcAMountToWithdraw && lfcAMountToWithdraw > 0) ||
      (solAmountToWithdraw && solAmountToWithdraw > 0)
    ) {
      try {
        setLoading(true);
        const transaction = new Transaction();

        const getTokenAddressAndInstruction = async (mint, owner, payer) => {
          let tokenAccountAddress = await getAssociatedTokenAddress(
            mint,
            owner,
            true
          );
          const accountInfo = await connection.getAccountInfo(
            tokenAccountAddress
          );
          if (accountInfo !== null) {
            return [tokenAccountAddress, null];
          }
          let tokenAccountCreationInstruction =
            createAssociatedTokenAccountInstruction(
              payer,
              tokenAccountAddress,
              owner,
              mint
            );
          return [tokenAccountAddress, tokenAccountCreationInstruction];
        };

        console.log("the _wallet os: ", LFC_PAIR);

        // Get or create user's token accounts
        const [userLfcTokenAccount, userLfcTokenAccountCreationInstruction] =
          await getTokenAddressAndInstruction(LFC_PAIR, _wallet, _wallet);

        if (userLfcTokenAccountCreationInstruction) {
          transaction.add(userLfcTokenAccountCreationInstruction);
        }

        const [
          wSOLTokenAccountOfUser,
          wSOLTokenAccountOfUserCreationInstruction,
        ] = await getTokenAddressAndInstruction(NATIVE_MINT, _wallet, _wallet);

        if (wSOLTokenAccountOfUserCreationInstruction) {
          transaction.add(wSOLTokenAccountOfUserCreationInstruction);
        }

        const [userLPTokenAccount, userLPTokenAccountCreationInstruction] =
          await getTokenAddressAndInstruction(LP_TOKEN, _wallet, _wallet);

        if (userLPTokenAccountCreationInstruction) {
          transaction.add(userLPTokenAccountCreationInstruction);
        }

        const tokenFeeAccountAddress = await getAssociatedTokenAddress(
          LP_TOKEN,
          fee_owner,
          true
        );

        const lfcAta = await getAssociatedTokenAddress(
          LFC_PAIR,
          swapAuthority,
          true
        );
        const wSOLAta = await getAssociatedTokenAddress(
          NATIVE_MINT,
          swapAuthority,
          true
        );

        const userLPAccountAddres = await getAssociatedTokenAddress(
          LP_TOKEN,
          _wallet
        );

        let lpBalanceOfUser = 0;

        if (userLPAccountAddres) {
          const userLPAccount = await getAccount(
            connection,
            userLPAccountAddres,
            "confirmed",
            TOKEN_PROGRAM_ID
          );

          lpBalanceOfUser = userLPAccount.amount;

          console.log("LP Balance of User is: ", lpBalanceOfUser);
        }

        if (isSol) {
          const solAmount = BigNumber(solAmountToWithdraw * LAMPORTS_PER_SOL);
          const withdrawSOLInstruction =
            TokenSwap.withdrawSingleTokenTypeExactAmountOutInstruction(
              tokenSwap,
              swapAuthority,
              _wallet,
              LP_TOKEN,
              tokenFeeAccountAddress,
              userLPTokenAccount,
              lfcAta,
              wSOLAta,
              wSOLTokenAccountOfUser,
              NATIVE_MINT,
              TOKEN_SWAP_PROGRAM_ID,
              TOKEN_PROGRAM_ID,
              TOKEN_PROGRAM_ID,
              solAmount,
              BigNumber(lpBalanceOfUser)
            );

          const getSOLInstruction = createCloseAccountInstruction(
            wSOLTokenAccountOfUser,
            _wallet,
            _wallet,
            [_wallet]
          );
          transaction.add(withdrawSOLInstruction);
          transaction.add(getSOLInstruction);
        } else {
          const lfcAmount = BigNumber(lfcAMountToWithdraw * TOKEN_A_DECIMALS);
          const withdrawLFCInstruction =
            TokenSwap.withdrawSingleTokenTypeExactAmountOutInstruction(
              tokenSwap,
              swapAuthority,
              _wallet,
              LP_TOKEN,
              tokenFeeAccountAddress,
              userLPTokenAccount,
              lfcAta,
              wSOLAta,
              userLfcTokenAccount,
              LFC_PAIR,
              TOKEN_SWAP_PROGRAM_ID,
              TOKEN_PROGRAM_ID,
              TOKEN_PROGRAM_ID,
              lfcAmount,
              BigNumber(lpBalanceOfUser)
            );

          transaction.add(withdrawLFCInstruction);
        }
        let recentBlockhash = (await connection.getLatestBlockhash()).blockhash;
        transaction.recentBlockhash = recentBlockhash;
        transaction.feePayer = _wallet;

        console.log("Sending transaction...");
        const signature = await window.solana.signAndSendTransaction(
          transaction
        );

        await connection.confirmTransaction(signature.signature, "confirmed");

        const _sign = signature.signature;
        console.log("_sign", signature.signature);

        const details = await getTransactionDetails(_sign, isSol);

        console.log("details>>>>>", details);

        await sendTransactionDetails(details, isSol);
        Swal.fire({
          title: "Success!",
          text: "Transaction completed successfully!",
          icon: "success",
          showCancelButton: true,
          cancelButtonText: "Close",
          confirmButtonText: "Check on Explorer",
        }).then((result) => {
          if (result.isConfirmed) {
            if (!_sign) {
              console.error("Signature not available");
              return;
            }
            const url = `https://explorer.solana.com/tx/${_sign}?cluster=devnet`;

            window.open(url, "_blank", "noopener,noreferrer");
          }
        });
        setLoading(false);
        setSolAmountToWithdraw("");
        setLfcAmountToWithdraw("");
      } catch (error) {
        console.error("Error withdrawing liquidity:", error);
        Swal.fire({
          title: "Error!",
          text: `Error: ${error.message}`,
          icon: "error",
          confirmButtonText: "OK",
        });
        setLoading(false);
      }
    } else {
      setError("Please enter amount more than 0");
    }
  };

  const getTransactionStatus = async (signature) => {
    try {
      const status = await connection.getSignatureStatus(signature);

      if (status?.value?.err === null) {
        console.log("Transaction completed successfully");
        return "Success";
      } else {
        console.log("Transaction failed with error: ", status?.value?.err);
        return "Failure";
      }
    } catch (err) {
      console.log("Transaction failed");
    }
  };

  const getTransactionDetails = async (signature, isSol) => {
    console.log("signature at 527", signature);
    try {
      const transaction = await connection.getTransaction(signature, {
        commitment: "confirmed",
      });

      console.log("The signature: transaction>>>>>>>", transaction);

      const status = await getTransactionStatus(signature);

      if (transaction) {
        if (isSol) {
          console.log("transaction", transaction);
          const buyAtDate = new Date(transaction.blockTime * 1000);
          const walletAddress =
            transaction.transaction.message.accountKeys[0].toString();
          const fee = transaction.meta.fee / LAMPORTS_PER_SOL;
          const updatedBalance =
            transaction.meta.postTokenBalances[3].uiTokenAmount.uiAmount;
          const previousTokenBalance =
            transaction.meta.preTokenBalances[3].uiTokenAmount.uiAmount;
          const withdrawnLFC = 0;

          const presSolBalance = transaction.meta.preBalances[0];
          const postSolBalances = transaction.meta.postBalances[0];

          const withdrawnSolana =
            (postSolBalances - presSolBalance) / LAMPORTS_PER_SOL - fee;

          return {
            signature,
            buyAtDate,
            walletAddress,
            fee,
            updatedBalance,
            withdrawnSolana,
            withdrawnLFC,
            status,
          };
        } else {
          console.log("transaction", transaction);
          const buyAtDate = new Date(transaction.blockTime * 1000);
          const walletAddress =
            transaction.transaction.message.accountKeys[0].toString();
          const fee = transaction.meta.fee / LAMPORTS_PER_SOL;
          const updatedBalance =
            transaction.meta.postTokenBalances[4].uiTokenAmount.uiAmount;
          const previousTokenBalance =
            transaction.meta.preTokenBalances[4].uiTokenAmount.uiAmount;
          const withdrawnLFC = updatedBalance - previousTokenBalance;

          const presSolBalance = transaction.meta.preBalances[0];
          const postSolBalances = transaction.meta.postBalances[0];

          const withdrawnSolana = 0;

          return {
            signature,
            buyAtDate,
            walletAddress,
            fee,
            updatedBalance,
            withdrawnSolana,
            withdrawnLFC,
            status,
          };
        }
      } else {
        throw new Error("Transaction not Found");
      }
    } catch (error) {
      console.error("Error fetching transactions: ", error);
      throw error;
    }
  };

  const sendTransactionDetails = async (details, isSol) => {
    try {
      if (isSol) {
        await axios.post(
          `${process.env.REACT_APP_API_ENDPOINT_URL}/liquidity/storeWithdrawalSolLiquidity`,
          details,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        console.log(
          "withdraw sol details send to backend successfully",
          details
        );
      } else {
        await axios.post(
          `${process.env.REACT_APP_API_ENDPOINT_URL}/liquidity/storeWithdrawalTokenLiquidity`,
          details,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        console.log(
          "withdraw lal details send to backend successfully",
          details
        );
      }
    } catch (error) {
      console.error("Error sending transaction details:", error);
    }
  };

  return (
    <>
      <NavBar />
      <div className={`sidebar-sec ${isOpen === true ? "active" : ""}`}>
        <section id="content-wrapper">
          <div className="user-dashboard">
            <div className="right-sidebar">
              <div className="right-sidebar-inner">
                <nav className="navbar navbar-expand-lg navbar-light">
                  <div className="right-toggle-menu-outer">
                    <div className="right-toggle-menu">
                      <div
                        className="right-toggle-menu-inner"
                        onClick={handleRightToggle}
                      >
                        <div className="wallent">
                          {walletAddress ? (
                            <button className="btn btn-one">
                              {walletAddress.substring(0, 4)}...
                              {walletAddress.slice(-4)}
                            </button>
                          ) : (
                            <button className="btn btn-two">Connect</button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </nav>
                <div
                  className={`right-sidebar ${isOpen === true ? "active" : ""}`}
                >
                  <div className="sd-header">
                    <div className="sd-header-btn" onClick={handleRightToggle}>
                      <i className="fa fa-times"></i>
                    </div>
                  </div>
                  <div className="sd-body">
                    <div className="row">
                      <div className="col-lg-12 col-sm-12">
                        <div className="connect-card">
                          <div className="card-body">
                            <div className="card-inner">
                              <div className="wallet-ui">
                                <div className="wallet-add">
                                  <h6
                                    className={`wallet-address ${
                                      walletAddress
                                        ? "wallet-connected"
                                        : "wallet-disconnected"
                                    }`}
                                  >
                                    {walletAddress ? "Wallet Address " : ""}
                                    {walletAddress
                                      ? `${walletAddress.substring(
                                          0,
                                          4
                                        )}...${walletAddress.slice(-4)}`
                                      : "Wallet not connected"}
                                  </h6>
                                  {walletAddress ? (
                                    <Tooltip title="Copy Wallet Address" arrow>
                                      <FontAwesomeIcon
                                        icon={faCopy}
                                        className="admin-lfc-deposit-copy"
                                        onClick={copyAccountToClipboard}
                                      />
                                    </Tooltip>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="admin-lfc-deposit-balance">
                                  {connected ? (
                                    <p>Balance: {balance} SOL</p>
                                  ) : (
                                    <p>Balance: 0 SOL</p>
                                  )}
                                </div>
                                <div className="wallet-btn">
                                  {connected ? (
                                    <button
                                      className="btn"
                                      onClick={disconnectWallet}
                                    >
                                      Disconnect Wallet
                                    </button>
                                  ) : (
                                    <button
                                      className="btn connect-wallet-btn"
                                      onClick={connectWallet}
                                    >
                                      <img
                                        src={phantomIcon}
                                        width="40"
                                        height="40"
                                      />
                                      Connect Wallet
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`sidebar-overlay ${
                    isOpen === true ? "active" : ""
                  }`}
                  onClick={handleRightToggle}
                ></div>
              </div>
            </div>
            <div className="user-dashboard-inner">
              <div className="user-dashboard-tab">
                <div className="container">
                  <div className="user-dashboard-tab-inner">
                    <Tabs
                      defaultActiveKey="withdrawsol"
                      id="uncontrolled-tab-example"
                      className=""
                    >
                      <Tab eventKey="withdrawsol" title="Withdraw SOL">
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <h3>Withdraw Liquidity SOL</h3>
                          <Form.Label>
                            SOL Amount<span>*</span>
                          </Form.Label>
                          <InputGroup className="">
                            <Form.Control
                              type="number"
                              value={solAmountToWithdraw}
                              onChange={(e) => {
                                setSolAmountToWithdraw(e.target.value);
                                setError("");
                              }}
                              placeholder="Solana amount"
                              required
                            />
                          </InputGroup>
                        </Form.Group>
                        {isConnected ? (
                          <div className="connect-wallet-one">
                            <Button
                              onClick={() => withdrawSingleLiquidity(true)}
                              disabled={loading}
                            >
                              {loading ? (
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              ) : (
                                "Withdraw Liquidity SOL"
                              )}
                            </Button>
                          </div>
                        ) : (
                          <div className="connect-wallet-one">
                            <button onClick={handleRightToggle}>
                              Connect Wallet
                            </button>
                          </div>
                        )}
                        <p>{error}</p>
                      </Tab>
                      <Tab eventKey="withdrawlfc" title="Withdraw $LAL">
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <h3>Withdraw Liquidity $LAL</h3>
                          <Form.Label>
                            $LAL Amount<span>*</span>
                          </Form.Label>
                          <InputGroup className="">
                            <Form.Control
                              type="number"
                              value={lfcAMountToWithdraw}
                              onChange={(e) => {
                                setLfcAmountToWithdraw(e.target.value);
                                setError("");
                              }}
                              placeholder="$LAL amount"
                              required
                            />
                          </InputGroup>
                        </Form.Group>
                        {isConnected ? (
                          <div className="connect-wallet-one">
                            <Button
                              onClick={() => withdrawSingleLiquidity(false)}
                              disabled={loading}
                            >
                              {loading ? (
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              ) : (
                                "Withdraw Liquidity $LAL"
                              )}
                            </Button>
                          </div>
                        ) : (
                          <div className="connect-wallet-one">
                            <button onClick={handleRightToggle}>
                              Connect Wallet
                            </button>
                          </div>
                        )}
                        <p>{error}</p>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default AdminWithdraw;
