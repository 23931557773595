import React, { useState } from "react";
import {
  validatePassword,
  validateConfirmPassword,
} from "../../utils/validation";
import { Link, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import lineImage from "../../assets/images/line-art-large.png";
import logoImage from "../../assets/images/logo-img.png";
import Swal from "sweetalert2";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [errorConfirmPassword, setErrorConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleResetPassword = (e) => {
    e.preventDefault();
    setLoading(true);

    if (!validatePassword(password)) {
      let errorMessage;
      if (password.length > 0 && password.length < 6) {
        errorMessage = "Password must be at least 6 characters long";
      } else if (password.length >= 6) {
        errorMessage =
          "Your password must contain at least one uppercase letter, one lowercase letter, one number, and one special character";
      } else {
        errorMessage = "Please enter a password";
      }
      setErrorPassword(errorMessage);
      setLoading(false);
      return;
    }

    if (!validateConfirmPassword(confirmPassword)) {
      let errorMessage;
      if (confirmPassword.length > 0 && confirmPassword.length < 6) {
        errorMessage = "Confirm Password must be at least 6 characters long";
      } else if (confirmPassword.length >= 6 && password !== confirmPassword) {
        errorMessage = "Passwords do not match";
      } else {
        errorMessage = "Please enter a confirm password";
      }
      setErrorConfirmPassword(errorMessage);
      setLoading(false);
      return;
    }

    if (password !== confirmPassword) {
      setError("confirm password doesn't matches with password");
      setLoading(false);
      return;
    }

    const token = window.location.pathname.split("/").pop();

    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        if (decodedToken.exp < currentTime) {
          Swal.fire({
            title: "Error!",
            text: "Token expired. Please request a new one.",
            icon: "error",
            confirmButtonText: "OK",
          });
        } else {
          axios
            .post(
              `${process.env.REACT_APP_API_ENDPOINT_URL}/users/resetPassword`,
              {
                token,
                password,
                confirmPassword,
              }
            )
            .then((response) => {
              if (response.status === 200) {
                Swal.fire({
                  title: "Success!",
                  text: "Your password has been reset successfully! Please login to continue",
                  icon: "success",
                  showCancelButton: true,
                  cancelButtonText: "Close",
                  confirmButtonText: "Go to Login",
                }).then((result) => {
                  if (result.isConfirmed) {
                    navigate("/login");
                  }
                });
                setPassword("");
                setConfirmPassword("");
                setError("");
                setErrorPassword("");
                setErrorConfirmPassword("");
              } else {
                Swal.fire({
                  title: "Error!",
                  text: `${response.data.message}`,
                  icon: "error",
                  confirmButtonText: "OK",
                });
              }
            })
            .catch((error) => {
              Swal.fire({
                title: "Error!",
                text: "Something Went Wrong! Please try again",
                icon: "error",
                confirmButtonText: "OK",
              });
            })
            .finally(() => {
              setLoading(false);
            });
        }
      } catch (error) {
        Swal.fire({
          title: "Error!",
          text: "Something Went Wrong! Please try again",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } else {
      Swal.fire({
        title: "Error!",
        text: "No token found in URL, please try again",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <>
      <div className="blockChainBg">
        <div className="target-anim">
          <img src={lineImage} alt="line" />
        </div>
        <div className="login-outer-sec">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="polygon-left-outer">
                  <div className="circle_all">
                    <div className="polygon-logo">
                      <img src={logoImage} alt="logo" />
                      <p>
                        Introducing LF Coin, the cryptocurrency for the largest
                        basketball fan community in the world. Holders can enjoy
                        exclusive Laker fan content, have the chance to win
                        merchandise, game-day giveaways, and even VIP Laker
                        tickets with special access at Crypto.com Arena.
                      </p>
                    </div>
                    <div className="circle_A"></div>
                    <div className="circle_B"></div>
                    <div className="circle_C"></div>
                    <div className="circle_D"></div>
                    <div className="circle_E"></div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="right-login-outer">
                  <div className="right-login">
                    <div className="form-2-wrapper">
                      <div className="logo">
                        <img src="assets/images/logo-img.png" alt="logo" />
                        <div className="form-head">Reset Password</div>
                      </div>
                      <div className="laker-coins animate__animated animate__bounce">
                        Welcome
                        <span className="fanCoins"> To LF Coin</span>
                      </div>
                      <form onSubmit={handleResetPassword}>
                        <div className="form-box">
                          <label htmlFor="">
                            New Password<span>*</span>
                          </label>
                          <input
                            id="password"
                            type={showPassword ? "text" : "password"}
                            className="form-control"
                            name="password"
                            value={password}
                            placeholder="Enter Your Password"
                            onChange={(e) => {
                              setPassword(e.target.value);
                              setErrorPassword("");
                              setError("");
                            }}
                            maxLength={12}
                          />
                          <span
                            onClick={togglePasswordVisibility}
                            className={`fa ${
                              showPassword ? "fa-eye" : "fa-eye-slash"
                            } field-icon toggle-password`}
                          ></span>
                        </div>
                        {errorPassword && (
                          <p className="text-warning">{errorPassword}</p>
                        )}
                        <div className="form-box">
                          <label htmlFor="">
                            Confirm New Password<span>*</span>
                          </label>
                          <input
                            id="confirmPassword"
                            type={showConfirmPassword ? "text" : "password"}
                            className="form-control"
                            name="password"
                            value={confirmPassword}
                            placeholder="Enter Your Password"
                            onChange={(e) => {
                              setConfirmPassword(e.target.value);
                              setErrorConfirmPassword("");
                              setError("");
                            }}
                            maxLength={12}
                          />
                          <span
                            onClick={toggleConfirmPasswordVisibility}
                            className={`fa ${
                              showConfirmPassword ? "fa-eye" : "fa-eye-slash"
                            } field-icon toggle-password`}
                          ></span>
                        </div>
                        {errorConfirmPassword && (
                          <p className="text-warning">{errorConfirmPassword}</p>
                        )}
                        {error && <p className="text-warning">{error}</p>}
                        <div className="login-btn">
                          <button
                            type="submit"
                            className="btn btn-outline-secondary"
                            disabled={loading}
                          >
                            {loading ? (
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            ) : (
                              "Submit"
                            )}
                          </button>
                        </div>
                      </form>
                      <div className="register-test">
                        <p>
                          Back to <Link to="/">Log In</Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
