import React, { useState } from "react";
import {
  validateEmail,
  validateFirstName,
  validateLastName,
  validateNoNumbersOrSpecialChars,
} from "../../utils/validation";
import lineImage from "../../assets/images/line-art-large.png";
import Swal from "sweetalert2";
import axios from "axios";
import "react-international-phone/style.css";
import { useNavigate } from "react-router-dom";
import ImageGif from "../../assets/images/Player_24_Emote.gif";

const Content = () => {
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [errorFirstName, setErrorFirstName] = useState("");
  const [errorLastName, setErrorLastName] = useState("");
  const [errorPhone, setErrorPhone] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorCouponCode, setErrorCouponCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);
  const [errorAcceptance, setErrorAcceptance] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [isCouponUsed, setIsCouponUsed] = useState(false);
  const navigate = useNavigate();

  function formatPhone(value) {
    let format = "";
    var numbers = value.replace(/\D/g, ""),
      char = { 0: "+1 (", 3: ") ", 6: " - " };
    if (numbers[0] === "1") numbers = numbers.slice(1);
    format = "";
    for (var i = 0; i < numbers.length; i++) {
      format += (char[i] || "") + numbers[i];
    }
    return format;
  }

  const handlePhoneChange = (e) => {
    let value = e.target.value.replace(/\D/g, "").substring(0, 11);
    let formattedPhone = formatPhone(value);
    setPhone(formattedPhone);
    console.log(formattedPhone);
    const cleanedPhone = formattedPhone.replace(/\D/g, "");
    if (cleanedPhone.length === 11) {
      setErrorPhone("");
    } else if (cleanedPhone.length > 1 && cleanedPhone.length < 11) {
      setErrorPhone("Incomplete phone number");
    }
  };

  const handleSignUp = (e) => {
    e.preventDefault();
    setLoading(true);

    if (!validateFirstName(firstname)) {
      setErrorFirstName(
        firstname.length > 0 && firstname.length < 3
          ? "First Name must be at least 3 characters long"
          : "Please enter first name"
      );
      setLoading(false);
      return;
    }

    if (!validateLastName(lastname)) {
      setErrorLastName(
        lastname.length > 0 && lastname.length < 3
          ? "Last Name must be at least 3 characters long"
          : "Please enter last name"
      );
      setLoading(false);
      return;
    }

    if (!validateEmail(email)) {
      setErrorEmail(
        email.length > 0 ? "Invalid email format" : "Please enter email"
      );
      setLoading(false);
      return;
    }

    if (!isAccepted) {
      setErrorAcceptance("Please accept the terms and conditions");
      setLoading(false);
      return;
    }

    const userData = {
      firstname,
      lastname,
      phone,
      email,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT_URL}/users/promotionalSignup`,
        userData
      )
      .then((response) => {
        if (response.data.status === 200) {
          setIsSuccess(true);
          setLoading(false);
          setTimeout(() => {
            setFirstName("");
            setLastName("");
            setPhone("");
            setEmail("");
            setErrorFirstName("");
            setErrorLastName("");
            setErrorPhone("");
            setErrorEmail("");
            setIsAccepted(false);
            setErrorAcceptance("");
          }, 8000);
        } else {
          Swal.fire({
            title: "Error!",
            text: `${response.data.message}`,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error!",
          text: "Something Went Wrong! Please try again",
          icon: "error",
          confirmButtonText: "OK",
        });
      })
      .finally(() => {
        setLoading(false);
      });
    // }
  };

  const handelHome = () => {
    setIsSuccess(false);
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  // const CouponSuccessMessage = () => (
  //   <>
  //     <div className="ani-image-home">
  //       <img src={lineImage} alt="img" />
  //     </div>
  //     <div className="home-btn-thanks">
  //       <button className="home-btn" type="button" onClick={handelHome}>
  //         <i class="fa fa-arrow-left" aria-hidden="true"></i>
  //       </button>
  //     </div>

  //     <div className="lend-cont-thanks">
  //       <div className="container">
  //         <div className="lend-cont-thanks-head">
  //           <h4>WELCOME TO THE COMMUNITY ! </h4>
  //           <div className="ifcoin-gif">
  //             <img src={ImageGif} alt="lfcoin" />
  //           </div>
  //           <p>
  //             Don't have a Solana wallet yet? No worries! You'll need one to
  //             receive your $LAL coins, and we'll provide you with a guide to
  //             help you set it up. Stay tuned, and thank you for joining the
  //             LFCoin community!
  //           </p>
  //           <p>Please check your email for further details.</p>
  //         </div>
  //         <div className="iframe-video">
  //           <iframe
  //             width=""
  //             height=""
  //             src="https://www.youtube.com/embed/7V_Phj2Ndb4?si=ikAAN6MAZZLj1XiM"
  //             title="YouTube video player"
  //             frameborder="0"
  //             allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
  //             referrerpolicy="strict-origin-when-cross-origin"
  //             allowFullScreen
  //           ></iframe>
  //         </div>
  //         <div className="lend-cont-thanks-inner-in">
  //           <p>
  //             <strong>Disclosure: </strong>
  //             LFCOIN $LAL is a deflationary MEME COIN. LFCOIN $LAL is not
  //             affiliated with the Los Angeles Lakers or the National Basketball
  //             Association (NBA). $LAL is not a security and carries no intrinsic
  //             value. Its primary purpose is to serve as a utility token within a
  //             crypto community, supporting engagement and interactions.
  //           </p>
  //         </div>
  //       </div>
  //     </div>
  //   </>
  // );

  const SuccessMessage = () => (
    <>
      <div className="ani-image-home">
        <img src={lineImage} alt="img" />
      </div>

      <div className="home-btn-thanks">
        <button className="home-btn" type="button" onClick={handelHome}>
          <i class="fa fa-arrow-left" aria-hidden="true"></i>
        </button>
      </div>

      <div className="lend-cont-thanks">
        <div className="lend-cont-thanks-head">
          <h4>Thank you for Registration!</h4>
        </div>
        <div className="lend-cont-thanks-inner">
          <h4>Disclosure</h4>
          <p>
            LF Coin ($LAL) is a community-driven meme coin created to celebrate
            sports fandom and foster community engagement. LF Coin is not
            affiliated with the Los Angeles Lakers, the National Basketball
            Association (NBA), or any associated organizations. The project
            operates independently and is solely focused on building a
            decentralized community of sports enthusiasts. As a meme coin, LF
            Coin carries no intrinsic value and should not be considered an
            investment or security.
          </p>
        </div>
      </div>
    </>
  );

  return (
    <>
      {!isSuccess && (
        <div className="home-container home-page">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="lend-cont">
                  <div className="lend-cont-inner">
                    <h4>LFCoin ($LAL)</h4>
                    <p>
                      is a community-driven cryptocurrency built on the Solana
                      blockchain, designed to engage and reward a passionate
                      community of sports fans. By participating in games,
                      activities, and governance, LF Coin offers fans a unique
                      way to celebrate sports fandom. This whitepaper outlines
                      the technical specifications, tokenomics, and utility of
                      LF Coin, showcasing how the ecosystem operates and
                      benefits holders. LF Coin incorporates limited DAO
                      mechanics, giving token holders basic voting rights on
                      project proposals. Deeper levels of community engagement
                      and governance are unlocked through ownership of our
                      digital membership, which provides access to exclusive
                      decision-making opportunities, events, and other benefits.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="laker-fan-coin-form">
                  <form onSubmit={handleSignUp}>
                    <h3>Sign up to Join Community</h3>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-box">
                          <label htmlFor="firstName">
                            First Name<span className="color-asterisk">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="firstName"
                            name="firstName"
                            placeholder="First Name"
                            value={firstname}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (validateNoNumbersOrSpecialChars(inputValue)) {
                                const capitalizedValue =
                                  capitalizeFirstLetter(inputValue);
                                setFirstName(capitalizedValue);
                                setErrorFirstName("");
                              } else {
                                setErrorFirstName(
                                  "Only alphabet characters are allowed."
                                );
                              }
                            }}
                            maxLength={16}
                          />
                          <span className="fa-solid fa-user field-icon"></span>
                        </div>
                        {errorFirstName && (
                          <div className="validation-mess">
                            <p className="text-danger">{errorFirstName}</p>
                          </div>
                        )}
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-box">
                          <label htmlFor="lastName">
                            Last Name<span className="color-asterisk">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="lastName"
                            name="lastName"
                            placeholder="Last Name"
                            value={lastname}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (validateNoNumbersOrSpecialChars(inputValue)) {
                                const capitalizedValue =
                                  capitalizeFirstLetter(inputValue);
                                setLastName(capitalizedValue);
                                setErrorLastName("");
                              } else {
                                setErrorLastName(
                                  "Only alphabet characters are allowed."
                                );
                              }
                            }}
                            maxLength={16}
                          />
                          <span className="fa-solid fa-user field-icon"></span>
                        </div>
                        {errorLastName && (
                          <div className="validation-mess">
                            <p className="text-danger">{errorLastName}</p>
                          </div>
                        )}
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="form-box">
                          <label htmlFor="email">
                            Email<span className="color-asterisk">*</span>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => {
                              setEmail(e.target.value);
                              setErrorEmail("");
                            }}
                            maxLength={40}
                          />
                          <span className="fa-solid fa-envelope field-icon"></span>
                        </div>
                        {errorEmail && (
                          <div className="validation-mess">
                            <p className="text-danger">{errorEmail}</p>
                          </div>
                        )}
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="custom-phone-input">
                          <label htmlFor="phone">Phone</label>
                          <input
                            type="tel"
                            className="form-control"
                            id="phone"
                            name="phone"
                            placeholder="Phone"
                            value={phone}
                            onChange={handlePhoneChange}
                            onBlur={() => {
                              if (phone === "+1 ") setPhone("");
                            }}
                          />
                          <span className="fa-solid fa-phone field-icon"></span>
                        </div>
                        {errorPhone && (
                          <div className="validation-mess">
                            <p className="text-danger">{errorPhone}</p>
                          </div>
                        )}
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div style={{ display: "flex", marginTop: "8px" }}>
                          <div
                            style={{ marginRight: "10px", marginTop: "4px" }}
                          >
                            <input
                              type="checkbox"
                              id="acceptance"
                              checked={isAccepted}
                              onChange={(e) => {
                                setIsAccepted(e.target.checked);
                                setErrorAcceptance("");
                              }}
                            />
                          </div>
                          <div>
                            <label htmlFor="acceptance">
                              By joining the LFCoin Community, you agree that
                              $LAL coins are promotional and not linked to any
                              other offers or obligations. They hold no
                              guaranteed value, and $LAL is not liable for any
                              losses or issues arising from receiving or using
                              the coins. No legal claims or recourse will be
                              accepted.
                            </label>
                          </div>
                        </div>
                        {errorAcceptance && (
                          <div className="validation-mess">
                            <p className="text-danger">{errorAcceptance}</p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="cstm-login-btn">
                      <button
                        type="submit"
                        className="btn w-100"
                        disabled={loading}
                      >
                        {loading ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="lend-cont-inner-in">
                    <p>
                      <strong>Disclosure: </strong>
                      LF Coin ($LAL) is a community-driven meme coin created to
                      celebrate sports fandom and foster community engagement.
                      LF Coin is not affiliated with the Los Angeles Lakers, the
                      National Basketball Association (NBA), or any associated
                      organizations. The project operates independently and is
                      solely focused on building a decentralized community of
                      sports enthusiasts. As a meme coin, LF Coin carries no
                      intrinsic value and should not be considered an investment
                      or security.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="ani-image-home">
            <img src={lineImage} alt="img" />
          </div>
        </div>
      )}
      {isSuccess ? <SuccessMessage /> : null}
      <div className="main-footer">
        <p>
          <i className="fa fa-copyright"></i> Copyright 2024 predesigned All
          rights Received
        </p>
      </div>
    </>
  );
};

export default Content;
