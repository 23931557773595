import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { validateEmail, validatePassword } from "../../utils/validation";
import axios from "axios";
import lineImage from "../../assets/images/line-art-large.png";
import logoImage from "../../assets/images/logo-img.png";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../../src/store/reducers/user";
// import googleImg from "../../assets/images/google-img.png";
// import facebookImg from "../../assets/images/facebook-img.png";

const LogInForm = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const storedUserData = useSelector((state) => state.userStore.user);

  useEffect(() => {
    if (storedUserData) {
      if (storedUserData && storedUserData.role) {
        if (storedUserData.role === "admin") {
          navigate("/admin-dashboard");
        } else if (storedUserData.role === "user") {
          navigate("/user-dashboard");
        } else {
          navigate("/");
        }
      }
    }
  }, [navigate, storedUserData]);

  useEffect(() => {
    const rememberedEmail = localStorage.getItem("rememberedEmail");
    const rememberedPassword = localStorage.getItem("rememberedPassword");
    if (rememberedEmail && rememberedPassword) {
      setRememberMe(true);
      setEmail(rememberedEmail);
      setPassword(rememberedPassword);
    }
  }, []);

  const handleLogin = (e) => {
    e.preventDefault();
    setLoading(true);

    if (!validateEmail(email)) {
      setErrorEmail(
        email.length > 0 ? "Please enter correct email" : "Please enter email"
      );
      setLoading(false);
      return;
    }

    if (!validatePassword(password)) {
      let errorMessage;
      if (password.length > 0 && password.length < 6) {
        errorMessage = "Password must be at least 6 characters long";
      } else if (password.length >= 6) {
        errorMessage =
          "Your password must contain at least one uppercase letter, one lowercase letter, one number, and one special character";
      } else {
        errorMessage = "Please enter a password";
      }
      setErrorPassword(errorMessage);
      setLoading(false);
      return;
    }
    axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT_URL}/users/signin`,
        {
          email,
          password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.status === 200) {
          const userData = {
            firstName: response.data.data[0].firstName,
            lastName: response.data.data[0].lastName,
            email: response.data.data[0].email,
            role: response.data.data[0].role,
            userId: response.data.data[0]._id,
            auth: response.data.auth,
          };
          dispatch(setUser(userData));

          if (userData) {
            if (userData && userData.role) {
              if (userData.role === "admin") {
                navigate("/admin-dashboard");
              } else {
                navigate("/user-dashboard");
              }
            }
          }

          if (rememberMe) {
            localStorage.setItem("rememberedEmail", email);
            localStorage.setItem("rememberedPassword", password);
          } else {
            localStorage.removeItem("rememberedEmail");
            localStorage.removeItem("rememberedPassword");
          }

          setEmail("");
          setPassword("");
          setErrorEmail("");
          setErrorPassword("");
        } else if (response.status === 201) {
          Swal.fire({
            title: "Error!",
            text: "Your email is not verified yet. We have sent a verification mail to your email. Please verify it to log in to your account. If you not received an email, please contact admin at info@lfcoin.com",
            icon: "error",
            confirmButtonText: "OK",
          });
        } else {
          Swal.fire({
            title: "Error!",
            text: `${response.data.message}`,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error!",
          text: "Internal server error! Please try again",
          icon: "error",
          confirmButtonText: "OK",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <div className="blockChainBg">
        <div className="target-anim">
          <img src={lineImage} alt="line" />
        </div>
        <div className="login-outer-sec">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="polygon-left-outer">
                  <div className="circle_all">
                    <div className="polygon-logo">
                      <img src={logoImage} alt="logo" />
                      <p>
                        Introducing LF Coin, the cryptocurrency for the largest
                        basketball fan community in the world. Holders can enjoy
                        exclusive Laker fan content, have the chance to win
                        merchandise, game-day giveaways, and even VIP Laker
                        tickets with special access at Crypto.com Arena.
                      </p>
                    </div>
                    <div className="circle_A"></div>
                    <div className="circle_B"></div>
                    <div className="circle_C"></div>
                    <div className="circle_D"></div>
                    <div className="circle_E"></div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="right-login-outer">
                  <div className="right-login">
                    <div className="form-2-wrapper">
                      <div className="logo">
                        <div className="form-head">Login</div>
                      </div>
                      <div className="laker-coins animate__animated animate__bounce">
                        Welcome
                        <span className="fanCoins"> To LF Coin</span>
                      </div>
                      <form onSubmit={handleLogin}>
                        <div className="form-box">
                          <label htmlFor="email">
                            Email<span>*</span>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            placeholder="Enter Your Email"
                            value={email}
                            onChange={(e) => {
                              setEmail(e.target.value);
                              setErrorEmail("");
                            }}
                            maxLength={32}
                          />
                          <span className="fa-solid fa-envelope field-icon"></span>
                        </div>
                        {errorEmail && (
                          <p className="text-warning">{errorEmail}</p>
                        )}
                        <div className="form-box">
                          <label htmlFor="password">
                            Password<span>*</span>
                          </label>
                          <input
                            id="password-field"
                            type={showPassword ? "text" : "password"}
                            className="form-control"
                            name="password"
                            value={password}
                            placeholder="Enter Your Password"
                            onChange={(e) => {
                              setPassword(e.target.value);
                              setErrorPassword("");
                            }}
                            maxLength={16}
                          />
                          <span
                            onClick={togglePasswordVisibility}
                            className={`fa ${
                              showPassword ? "fa-eye" : "fa-eye-slash"
                            } field-icon toggle-password`}
                          ></span>
                        </div>
                        {errorPassword && (
                          <p className="text-warning">{errorPassword}</p>
                        )}

                        <div className="remember-one">
                          <div className="formCheck-one">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="rememberMe"
                              checked={rememberMe}
                              onChange={(e) => setRememberMe(e.target.checked)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="rememberMe"
                            >
                              Remember me
                            </label>
                          </div>
                          <Link to="/forgot-password">Forgot Password?</Link>
                        </div>
                        <div className="login-btn">
                          <button
                            type="submit"
                            className="btn btn-outline-secondary"
                            disabled={loading}
                          >
                            {loading ? (
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            ) : (
                              "Login"
                            )}
                          </button>
                        </div>
                        {/* <div className="social-login">
                          <h5>Or Login With</h5>
                          <div className="BtnGroup">
                            <button className="gogle-icon">
                              <img src={googleImg} alt="Google Icon" />
                              Login with Google
                            </button>
                            <button className="gogle-icon">
                              <img src={facebookImg} alt="Facebook Icon" />
                              Login with Facebook
                            </button>
                          </div>
                        </div> */}
                      </form>
                      <div className="register-test">
                        <p>
                          Don't have an account?
                          <Link to="/sign-up"> Sign up</Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LogInForm;
