import React, { useState, useEffect } from "react";
import NavBar from "../../../components/navbar/NavBar";
import MoonPayView from "../../moonpay/MoonPayView";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import Tooltip from "@mui/material/Tooltip";
import { Connection, LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";
import LiquidityPool from "../../../components/liquidity-pool/Swap_token";
import { calculateExchangeRate } from "../../../utils/helper";
import phantomIcon from "../../../assets/images/phantom.png";
import BigNumber from "bignumber.js";
import SolanaPrice from "../../../components/websocket/SolanaPrice";
import * as buffer from "buffer";
import useWallet from "../../../utils/hooks";
import SendSOL from "../../../components/SendSOL";

const rpc = process.env.REACT_APP_RPC_URL;

window.buffer = buffer.Buffer;

const connection = new Connection(rpc);

const Purchase = () => {
  const {
    isOpen,
    connected,
    walletAddress,
    balance,
    isConnected,
    connectWallet,
    disconnectWallet,
    handleRightToggle,
    copyAccountToClipboard,
    setIsOpen,
  } = useWallet();
  const [inputs, setInputs] = useState({ sol: "", lfc: "" });
  const [error, setError] = useState("");
  const [buyAmount, setBuyAmount] = useState("");
  const [from, setFrom] = useState("sol");
  const [isSwapped, setIsSwapped] = useState(false);

  const swapContainers = () => {
    setIsSwapped((prev) => !prev);
    setFrom(from == "sol" ? "lfc" : "sol");
  };

  const handleInputChange = async (event) => {
    const { name, value } = event.target;
    const newInputs = { ...inputs };
    newInputs[name] = value;

    // Validate input
    // const numValue = Number.isFinite(parseFloat(value)) ? parseFloat(value) : 0;

    const numValue = parseFloat(value);

    // Check for minimum value of 20
    if (inputs === "sol") {
      if (isNaN(numValue) || numValue < 20) {
        setError("Amount must be at least 20.");
        return; // Exit the function if validation fails
      } else {
        setError(""); // Clear error if validation passes
      }
    }

    // Convert SOL to LFC
    if (name === "sol") {
      // const lfcValue = numValue * 13000;
      // newInputs.lfc = lfcValue.toFixed(4);
      // const [rate, fee, burn_fee] = await calculateExchangeRate(
      //   BigNumber(value),
      //   from
      // );
      // newInputs.lfc = rate.toFixed(6);
    }
    // Convert LFC to SOL
    else if (name === "lfc") {
      // const solValue = numValue / 13000;
      // newInputs.sol = solValue.toFixed(4);
      newInputs.sol = "";
    }

    setInputs(newInputs);
  };

  const getEstimation = async () => {
    let amountToSend;
    if (from === "sol") {
      amountToSend = inputs.sol;
    } else {
      amountToSend = inputs.lfc;
    }
    const [rate, fee, burn_fee] = await calculateExchangeRate(
      BigNumber(amountToSend),
      from,
      isConnected
    );

    if (from === "sol") {
      const lfc = rate.toFixed(6);
      setInputs((prevState) => ({
        ...prevState,
        lfc: lfc,
      }));
    } else {
      const wSOL = rate.toFixed(6);
      setInputs((prevState) => ({
        ...prevState,
        sol: wSOL,
      }));
    }
  };

  const handleBuySolAmountChange = (event) => {
    setBuyAmount(event.target.value);
    setError("");
  };

  return (
    <>
      <NavBar />
      <div className={`sidebar-sec ${isOpen === true ? "active" : ""}`}>
        <section id="content-wrapper">
          <h4 className="my-dash">Purchase SOL and $LAL Tokens</h4>
          <div className="user-dashboard">
            <div className="right-sidebar">
              <div className="right-sidebar-inner">
                <nav className="navbar navbar-expand-lg navbar-light">
                  <div className="right-toggle-menu-outer">
                    <div className="right-toggle-menu">
                      <div
                        className="right-toggle-menu-inner"
                        onClick={handleRightToggle}
                      >
                        <div className="wallent">
                          {walletAddress ? (
                            <button className="btn btn-one">
                              {walletAddress.substring(0, 4)}...
                              {walletAddress.slice(-4)}
                            </button>
                          ) : (
                            <button className="btn btn-two">Connect</button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </nav>
                <div
                  className={`right-sidebar ${isOpen === true ? "active" : ""}`}
                >
                  <div className="sd-header">
                    <div className="sd-header-btn" onClick={handleRightToggle}>
                      <i className="fa fa-times"></i>
                    </div>
                  </div>
                  <div className="sd-body">
                    <div className="row">
                      <div className="col-lg-12 col-sm-12">
                        <div className="connect-card">
                          <div className="card-body">
                            <div className="card-inner">
                              <div className="wallet-ui">
                                <div className="wallet-add">
                                  <h6
                                    className={`wallet-address ${
                                      walletAddress
                                        ? "wallet-connected"
                                        : "wallet-disconnected"
                                    }`}
                                  >
                                    {walletAddress ? "Wallet Address " : ""}
                                    {walletAddress
                                      ? `${walletAddress.substring(
                                          0,
                                          4
                                        )}...${walletAddress.slice(-4)}`
                                      : "Wallet not connected"}
                                  </h6>
                                  {walletAddress ? (
                                    <Tooltip title="Copy Wallet Address" arrow>
                                      <FontAwesomeIcon
                                        icon={faCopy}
                                        className="admin-lfc-deposit-copy"
                                        onClick={copyAccountToClipboard}
                                      />
                                    </Tooltip>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="admin-lfc-deposit-balance">
                                  {connected ? (
                                    <p>Balance: {balance} SOL</p>
                                  ) : (
                                    <p>Balance: 0 SOL</p>
                                  )}
                                </div>
                                <div className="wallet-btn">
                                  {connected ? (
                                    <button
                                      className="btn"
                                      onClick={disconnectWallet}
                                    >
                                      Disconnect Wallet
                                    </button>
                                  ) : (
                                    <button
                                      className="btn connect-wallet-btn"
                                      onClick={connectWallet}
                                    >
                                      <img
                                        src={phantomIcon}
                                        width="40"
                                        height="40"
                                      />
                                      Connect Wallet
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`sidebar-overlay ${
                    isOpen === true ? "active" : ""
                  }`}
                  onClick={handleRightToggle}
                ></div>
              </div>
            </div>
            <div className="user-dashboard-inner">
              <div className="user-dashboard-tab">
                <div className="container">
                  <div className="user-dashboard-tab-inner">
                    <Tabs
                      defaultActiveKey="send"
                      id="uncontrolled-tab-example"
                      className=""
                    >
                      {/* <Tab eventKey="swap" title="Swap">
                        <div className="mb-3 solana-display">
                          <h6>Current Solana Price :</h6>
                          <SolanaPrice />
                        </div>
                        <div className="mb-3 solana-display">
                          <h6>Current $LAL Price : $0.0005 USD</h6>
                        </div>
                        <div className="container-wrapper">
                          {isSwapped ? (
                            <>
                              <div className="user-dash-tab-form">
                                <Form.Label>
                                  Sell $LAL Coin<span>*</span>
                                </Form.Label>
                                <InputGroup className="">
                                  <Form.Control
                                    type="number"
                                    aria-label="Number input with dropdown button"
                                    value={inputs.lfc}
                                    onChange={handleInputChange}
                                    name="lfc"
                                    placeholder="$LAL amount"
                                  />
                                </InputGroup>
                              </div>

                              <div className="down-arrow-tab">
                                <span
                                  onClick={swapContainers}
                                  className="admin-lfc-deposit-copy"
                                >
                                  <i className="fa-solid fa-down-long"></i>
                                  <i className="fa-solid fa-up-long"></i>
                                </span>
                              </div>

                              <div className="user-dash-tab-form">
                                <Form.Label>
                                  Buy Solana<span>*</span>
                                </Form.Label>
                                <InputGroup className="">
                                  <Form.Control
                                    type="number"
                                    aria-label="Number input with dropdown button"
                                    value={inputs.sol}
                                    onChange={handleInputChange}
                                    name="sol"
                                    placeholder="Solana amount"
                                  />
                                  {error && <div>{error}</div>}
                                </InputGroup>
                                {inputs.lfc !== "" && isConnected !== null && (
                                  <button onClick={getEstimation}>
                                    Get Estimation*
                                  </button>
                                )}
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="user-dash-tab-form">
                                <Form.Label>
                                  Sell Solana<span>*</span>
                                </Form.Label>
                                <InputGroup className="">
                                  <Form.Control
                                    type="number"
                                    aria-label="Number input with dropdown button"
                                    value={inputs.sol}
                                    onChange={handleInputChange}
                                    name="sol"
                                    placeholder="Solana amount"
                                  />
                                </InputGroup>
                              </div>

                              <div className="down-arrow-tab">
                                <span
                                  onClick={swapContainers}
                                  className="admin-lfc-deposit-copy"
                                >
                                  <i className="fa-solid fa-up-long"></i>
                                  <i className="fa-solid fa-down-long"></i>
                                </span>
                              </div>

                              <div className="user-dash-tab-form">
                                <Form.Label>
                                  Buy $LAL Coin<span>*</span>
                                </Form.Label>
                                <InputGroup className="">
                                  <Form.Control
                                    type="number"
                                    aria-label="Number input with dropdown button"
                                    value={inputs.lfc}
                                    onChange={handleInputChange}
                                    name="lfc"
                                    placeholder="$LAL amount"
                                  />
                                </InputGroup>
                                {inputs.sol !== "" && isConnected !== null && (
                                  <button onClick={getEstimation}>
                                    Get Estimation*
                                  </button>
                                )}
                              </div>
                            </>
                          )}
                        </div>

                        <LiquidityPool
                          solAmount={inputs.sol}
                          lfcAmount={inputs.lfc}
                          _wallet={isConnected}
                          isOpen={isOpen}
                          setIsOpen={setIsOpen}
                          setInput={setInputs}
                          from={from}
                          isSwapped={isSwapped}
                        />

                        <div>
                          <div className="mb-2">
                            <h6>Disclaimer:</h6>
                          </div>
                          <p>
                            Note: Each transaction you perform, there will be
                          </p>
                          <p>Swap Fee: 0.2%</p>
                          <p>Burn Fee: 0.05%</p>
                        </div>
                      </Tab> */}
                      <Tab eventKey="send" title="Buy Token">
                        <SendSOL
                          connectedWallet={isConnected}
                          connection={connection}
                          isOpen={isOpen}
                          setIsOpen={setIsOpen}
                        />
                      </Tab>
                      <Tab eventKey="buy" title="Buy Solana">
                        <div className="mb-3 solana-display">
                          <i className="fa fa-arrow-right arrow-price"></i>
                          <h6>Current Solana Price :</h6>
                          <SolanaPrice />
                        </div>
                        <div className="mb-3 solana-display">
                          <i className="fa fa-arrow-right arrow-price"></i>
                          <h6>Current $LAL Price : $0.0005 USD</h6>
                        </div>
                        <div className="send-tab">
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>
                              Amount in Dollar<span>*</span>
                            </Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="Enter Dollar Amount"
                              value={buyAmount}
                              onChange={handleBuySolAmountChange}
                              required
                            />
                          </Form.Group>
                        </div>
                        <div className="swap-btn">
                          <MoonPayView
                            userInput={buyAmount}
                            wallet={isConnected}
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            setBuyAmount={setBuyAmount}
                            walletAddress={walletAddress}
                            error={error}
                            setError={setError}
                          />
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Purchase;
