import React, { useState, useEffect } from "react";
import BuyWidget from "../moonpay/MoonPayWidget";
import Button from "react-bootstrap/Button";

const MoonPayView = ({
  userInput,
  wallet,
  isOpen,
  setIsOpen,
  setBuyAmount,
  walletAddress,
  error,
  setError,
}) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const Email = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user")).email
    : "";

  const handleConnectWallet = () => {
    setIsOpen(!isOpen);
  };

  const handleBuyNowClick = () => {
    if (userInput <= 0) {
      setError("Please enter amount more than 0");
    } else {
      setError("");
      setVisible(true);
      setLoading(true);
    }
  };

  return (
    <>
      {wallet ? (
        <>
          <div className="wallet-input">
            <div className="connect-wallet">
              <div className="connect-wallet-one">
                <Button onClick={handleBuyNowClick} disabled={loading}>
                  {loading ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    "Buy Now"
                  )}
                </Button>
              </div>
              {userInput ? (
                <BuyWidget
                  visible={visible}
                  email={Email}
                  walletAddress={walletAddress}
                  userInput={userInput}
                  setBuyAmount={setBuyAmount}
                  setLoading={setLoading}
                />
              ) : (
                ""
              )}
            </div>
            {error && <p>{error}</p>}
          </div>
        </>
      ) : (
        <div className="connect-wallet-one">
          <button onClick={handleConnectWallet}>Connect Wallet</button>
        </div>
      )}
    </>
  );
};

export default MoonPayView;
