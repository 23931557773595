import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {
    firstName: "",
    lastName: "",
    email: "",
    role: "",
    userId: "",
    auth: "",
  },
};

export const userSlice = createSlice({
  name: "userStore",
  initialState: () => {
    const tempUser = localStorage["user"];
    if (tempUser) return { user: JSON.parse(tempUser) };
    return initialState;
  },
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
      localStorage.setItem("user", JSON.stringify(action.payload));
    },
    resetUser(state) {
      state.user = initialState.user;
      localStorage.removeItem("user");
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUser, resetUser } = userSlice.actions;

export default userSlice.reducer;
