import { useState } from "react";
import NavBar from "../../../components/navbar/NavBar";
import SolanaPrice from "../../../components/websocket/SolanaPrice";
import useWallet from "../../../utils/hooks";

const UserDashboard = () => {
  const { connected, balance, balanceLAL, connectWallet } = useWallet();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <NavBar />
      <div className={`sidebar-sec ${isOpen ? "active" : ""}`}>
        <section id="content-wrapper">
          <h4 className="my-dash">My Dashboard</h4>
          <div className="container-fluid ">
            <div className="dashboard-card-sec">
              <div className="row align-items-stretch solana-display">
                <div className=" col-lg-3 col-md-6">
                  <div className="c-dashboardInfo">
                    <div className="wrap">
                      <h4 className="heading heading5 hind-font medium-font-weight c-dashboardInfo__title">
                        Current Solana Price
                      </h4>
                      <span className="hind-font caption-12 c-dashboardInfo__count solana-user">
                        <SolanaPrice />
                      </span>
                    </div>
                  </div>
                </div>

                <div className=" col-lg-3 col-md-6">
                  <div className="c-dashboardInfo">
                    <div className="wrap">
                      <h4 className="heading heading5 hind-font medium-font-weight c-dashboardInfo__title">
                        Current $LAL Price
                      </h4>
                      <span className="hind-font caption-12 c-dashboardInfo__count">
                        <p className="display-balance">$0.0005 USD</p>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="c-dashboardInfo ">
                    <div className="wrap">
                      <h4 className="heading heading5 hind-font medium-font-weight c-dashboardInfo__title">
                        Solana Balance
                      </h4>
                      <span className="hind-font caption-12 c-dashboardInfo__count">
                        {connected ? (
                          <p className="display-balance">{balance} SOL</p>
                        ) : (
                          <button
                            className="btn btn-primary"
                            onClick={connectWallet}
                          >
                            Connect Wallet
                          </button>
                        )}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="c-dashboardInfo">
                    <div className="wrap">
                      <h4 className="heading heading5 hind-font medium-font-weight c-dashboardInfo__title">
                        $LAL Balance
                      </h4>
                      <span className="hind-font caption-12 c-dashboardInfo__count">
                        {connected ? (
                          <p className="display-balance">{balanceLAL} $LAL</p>
                        ) : (
                          <button
                            className="btn btn-primary"
                            onClick={connectWallet}
                          >
                            Connect Wallet
                          </button>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default UserDashboard;
