import React, { useState, useEffect } from "react";
import NavBar from "../../../components/navbar/NavBar";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import { PhantomWalletAdapter } from "@solana/wallet-adapter-phantom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import Swal from "sweetalert2";
import Tooltip from "@mui/material/Tooltip";
import phantomIcon from "../../../assets/images/phantom.png";
import axios from "axios";
import { Transaction, SystemProgram, LAMPORTS_PER_SOL } from "@solana/web3.js";
import { TokenSwap } from "@solana/spl-token-swap";
import {
  getAssociatedTokenAddress,
  NATIVE_MINT,
  TOKEN_PROGRAM_ID,
  createSyncNativeInstruction,
  getAccount,
  getMint,
  ASSOCIATED_TOKEN_PROGRAM_ID,
  createAssociatedTokenAccountInstruction,
} from "@solana/spl-token";
import {
  TOKEN_SWAP_PROGRAM_ID,
  LFC_PAIR,
  LP_TOKEN,
  TOKEN_A_DECIMALS,
  tokenSwap,
  connection,
  swapAuthority,
} from "../../../constants/constants";
import BigNumber from "bignumber.js";
import { useSelector } from "react-redux";
import useWallet from "../../../utils/hooks";

const calculateLPTokens = (
  tokenA_pool,
  tokenB_pool,
  LP_total_supply,
  tokenA_deposit,
  tokenB_deposit
) => {
  let proportionA, proportionB, proportion;

  proportionA = tokenA_deposit
    .times(new BigNumber(1e6))
    .dividedToIntegerBy(tokenA_pool);
  console.log("Proportion A ", proportionA);

  proportionB = tokenB_deposit
    .times(new BigNumber(1e6))
    .dividedToIntegerBy(tokenB_pool);
  console.log("Proportion B ", proportionB);
  proportion = proportionA < proportionB ? proportionA : proportionB;
  console.log("Proportion", proportion);
  return proportion
    .times(LP_total_supply)
    .dividedToIntegerBy(new BigNumber(1e6));
};

const AdminLFCDeposit = () => {
  const {
    isOpen,
    connected,
    walletAddress,
    balance,
    isConnected,
    connectWallet,
    disconnectWallet,
    handleRightToggle,
    copyAccountToClipboard,
  } = useWallet();

  const [tokenAAmount, setTokenAAmount] = useState("");
  const [tokenBAmount, setTokenBAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const user = useSelector((state) => state.userStore.user);

  const token = user.auth;

  const getTokenAddressAndInstruction = async (mint, owner, payer, type) => {
    let tokenAccountAddress = await getAssociatedTokenAddress(
      mint,
      owner,
      true,
      TOKEN_PROGRAM_ID,
      ASSOCIATED_TOKEN_PROGRAM_ID
    );

    console.log(
      `The assciated of ${type} account is: `,
      tokenAccountAddress.toBase58()
    );
    const accountInfo = await connection.getAccountInfo(tokenAccountAddress);
    if (accountInfo !== null) {
      console.log("Associated token account already exists");
      return [tokenAccountAddress, null];
    }

    console.log("");

    let tokenAccountCreationInstruction =
      createAssociatedTokenAccountInstruction(
        payer,
        tokenAccountAddress,
        owner,
        mint,
        TOKEN_PROGRAM_ID,
        ASSOCIATED_TOKEN_PROGRAM_ID
      );
    return [tokenAccountAddress, tokenAccountCreationInstruction];
  };

  const depositSingleTokenLiquidity = async (isSol) => {
    if (
      (tokenAAmount && tokenAAmount > 0) ||
      (tokenBAmount && tokenBAmount > 0)
    ) {
      try {
        setLoading(true);
        const transaction = new Transaction();

        const _wallet = isConnected;

        const LFC_AMOUNT = tokenAAmount * TOKEN_A_DECIMALS;
        const SOL_AMOUNT = tokenBAmount * LAMPORTS_PER_SOL;

        console.log("The LFC and SOL accounts are: ", LFC_AMOUNT, SOL_AMOUNT);

        // Create or get user's token accounts
        const [userLfcTokenAccount, userLfcTokenAccountCreationInstruction] =
          await getTokenAddressAndInstruction(
            LFC_PAIR,
            _wallet,
            _wallet,
            "User LFC Token Account"
          );

        if (userLfcTokenAccountCreationInstruction) {
          transaction.add(userLfcTokenAccountCreationInstruction);
        }

        const [
          wSOLTokenAccountOfUser,
          wSOLTokenAccountOfUserCreationInstruction,
        ] = await getTokenAddressAndInstruction(
          NATIVE_MINT,
          _wallet,
          _wallet,
          "User SOL Token Account"
        );

        if (wSOLTokenAccountOfUserCreationInstruction) {
          transaction.add(wSOLTokenAccountOfUserCreationInstruction);
        }

        if (isSol) {
          // Transfer SOL to wrapped SOL account
          const transferSolInstruction = SystemProgram.transfer({
            fromPubkey: _wallet,
            toPubkey: wSOLTokenAccountOfUser,
            lamports: SOL_AMOUNT,
          });
          transaction.add(transferSolInstruction);

          const syncNativeInstruction = createSyncNativeInstruction(
            wSOLTokenAccountOfUser
          );
          transaction.add(syncNativeInstruction);
        }

        // Get or create user's LP token account
        const [destAddress, destAddressInstruction] =
          await getTokenAddressAndInstruction(
            LP_TOKEN,
            _wallet,
            _wallet,
            "Destination address token account"
          );

        if (destAddressInstruction) {
          transaction.add(destAddressInstruction);
        }

        // Get pool token accounts
        const lfcAta = await getAssociatedTokenAddress(
          LFC_PAIR,
          swapAuthority,
          true
        );
        const wSOLAta = await getAssociatedTokenAddress(
          NATIVE_MINT,
          swapAuthority,
          true
        );

        // Get pool token balances
        const lfcTokenBalance = await getAccount(connection, lfcAta);
        const wSolTokenBalance = await getAccount(connection, wSOLAta);

        const balance_a = lfcTokenBalance.amount;
        const balance_b = wSolTokenBalance.amount;

        console.log("The balance of token a nad b are: ", balance_a, balance_b);

        // Get LP token supply
        const lpTokenMintInfo = await getMint(connection, LP_TOKEN);
        const lpTokenTotalSupply = lpTokenMintInfo.supply;

        console.log("the LP token total supply is: ", lpTokenTotalSupply);

        const newLFCAmount = new BigNumber(LFC_AMOUNT);
        const newSolAmount = new BigNumber(SOL_AMOUNT);
        // Calculate LP tokens to mint
        const _lpTokens = calculateLPTokens(
          balance_a,
          balance_b,
          lpTokenTotalSupply,
          newLFCAmount,
          newSolAmount
        );

        console.log("Tokens from the calcualtion are: ", _lpTokens.toString());

        if (isSol) {
          const depositSOlInstruction =
            TokenSwap.depositSingleTokenTypeExactAmountInInstruction(
              tokenSwap,
              swapAuthority,
              _wallet,
              wSOLTokenAccountOfUser,
              lfcAta,
              wSOLAta,
              LP_TOKEN,
              destAddress,
              NATIVE_MINT,
              TOKEN_SWAP_PROGRAM_ID,
              TOKEN_PROGRAM_ID,
              TOKEN_PROGRAM_ID,
              BigNumber(SOL_AMOUNT),
              0n
            );

          transaction.add(depositSOlInstruction);
        } else {
          const depositLFCInstruction =
            TokenSwap.depositSingleTokenTypeExactAmountInInstruction(
              tokenSwap,
              swapAuthority,
              _wallet,
              userLfcTokenAccount,
              lfcAta,
              wSOLAta,
              LP_TOKEN,
              destAddress,
              LFC_PAIR,
              TOKEN_SWAP_PROGRAM_ID,
              TOKEN_PROGRAM_ID,
              TOKEN_PROGRAM_ID,
              BigNumber(LFC_AMOUNT),
              0n
            );

          transaction.add(depositLFCInstruction);
        }

        let recentBlockhash = (await connection.getLatestBlockhash()).blockhash;
        transaction.recentBlockhash = recentBlockhash;
        transaction.feePayer = _wallet;

        console.log("Sending transaction...");
        const signature = await window.solana.signAndSendTransaction(
          transaction
        );
        await connection.confirmTransaction(signature.signature, "confirmed");

        const _sign = signature.signature;
        console.log("_sign", signature.signature);

        const details = await getTransactionDetails(_sign, isSol);

        console.log("details>>>>>", details);

        await sendTransactionDetails(details, isSol);
        Swal.fire({
          title: "Success!",
          text: "Transaction completed successfully!",
          icon: "success",
          showCancelButton: true,
          cancelButtonText: "Close",
          confirmButtonText: "Check on Explorer",
        }).then((result) => {
          if (result.isConfirmed) {
            if (!_sign) {
              console.error("Signature not available");
              return;
            }
            const url = `https://explorer.solana.com/tx/${_sign}?cluster=devnet`;

            window.open(url, "_blank", "noopener,noreferrer");
          }
        });
        setLoading(false);
        setTokenAAmount("");
        setTokenBAmount("");
      } catch (error) {
        console.error("Error depositing liquidity:", error);
        Swal.fire({
          title: "Error!",
          text: `Error: ${error.message}`,
          icon: "error",
          confirmButtonText: "OK",
        });
        setLoading(false);
      }
    } else {
      setError("Please enter amount more than 0");
    }
  };

  const getTransactionStatus = async (signature) => {
    try {
      const status = await connection.getSignatureStatus(signature);

      if (status?.value?.err === null) {
        console.log("Transaction completed successfully");
        return "Success";
      } else {
        console.log("Transaction failed with error: ", status?.value?.err);
        return "Failure";
      }
    } catch (err) {
      console.log("Transaction failed");
    }
  };

  const getTransactionDetails = async (signature, isSol) => {
    console.log("signature at 527", signature);
    try {
      const transaction = await connection.getTransaction(signature, {
        commitment: "confirmed",
      });

      console.log("The signature: transaction>>>>>>>", transaction);

      const status = await getTransactionStatus(signature);

      if (transaction) {
        if (isSol) {
          const buyAtDate = new Date(transaction.blockTime * 1000);
          const walletAddress =
            transaction.transaction.message.accountKeys[0].toString();
          const fee = transaction.meta.fee / LAMPORTS_PER_SOL;
          const updatedBalance =
            transaction.meta.postTokenBalances[0].uiTokenAmount.uiAmount;
          const previousTokenBalance =
            transaction.meta.preTokenBalances[0].uiTokenAmount.uiAmount;
          const lpTokenObtained = updatedBalance - previousTokenBalance;

          const presSolBalance = transaction.meta.preBalances[0];
          const postSolBalances = transaction.meta.postBalances[0];

          const _solanaDeposited =
            (presSolBalance - postSolBalances) / LAMPORTS_PER_SOL;

          const solanaDeposited = _solanaDeposited - fee;
          console.log("status", status);

          return {
            signature,
            buyAtDate,
            walletAddress,
            fee,
            updatedBalance,
            solanaDeposited,
            lpTokenObtained,
            status,
          };
        } else {
          const buyAtDate = new Date(transaction.blockTime * 1000);
          const walletAddress =
            transaction.transaction.message.accountKeys[0].toString();
          const fee = transaction.meta.fee / LAMPORTS_PER_SOL;
          const updatedBalance =
            transaction.meta.postTokenBalances[0].uiTokenAmount.uiAmount;
          const previousTokenBalance =
            transaction.meta.preTokenBalances[0].uiTokenAmount.uiAmount;
          const lpTokenObtained = updatedBalance - previousTokenBalance;

          const updatedLFCBalance =
            transaction.meta.postTokenBalances[3].uiTokenAmount.uiAmount;
          const prevLFcBal =
            transaction.meta.preTokenBalances[3].uiTokenAmount.uiAmount;
          const lfcDeposited = prevLFcBal - updatedLFCBalance;

          const presSolBalance = transaction.meta.preBalances[0];
          const postSolBalances = transaction.meta.postBalances[0];

          const solanaDeposited =
            (presSolBalance - postSolBalances) / LAMPORTS_PER_SOL - fee;
          console.log("status", status);

          return {
            signature,
            buyAtDate,
            walletAddress,
            fee,
            updatedBalance,
            lfcDeposited,
            lpTokenObtained,
            status,
          };
        }
      } else {
        throw new Error("Transaction not Found");
      }
    } catch (error) {
      console.error("Error fetching transactions: ", error);
      throw error;
    }
  };

  const sendTransactionDetails = async (details, isSol) => {
    try {
      if (isSol) {
        await axios.post(
          `${process.env.REACT_APP_API_ENDPOINT_URL}/liquidity/storeDepositedSolLiquidity`,
          details,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        console.log(
          "SOL deposit details send to backend successfully ",
          details
        );
      } else {
        await axios.post(
          `${process.env.REACT_APP_API_ENDPOINT_URL}/liquidity/storeDepositedTokenLiquidity`,
          details,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        console.log(
          "$LAL deposit details send to backend successfully ",
          details
        );
      }
    } catch (error) {
      console.error("Error sending transaction details:", error);
    }
  };

  return (
    <>
      <NavBar />
      <div className={`sidebar-sec ${isOpen === true ? "active" : ""}`}>
        <section id="content-wrapper">
          <div className="user-dashboard">
            <div className="right-sidebar">
              <div className="right-sidebar-inner">
                <nav className="navbar navbar-expand-lg navbar-light">
                  <div className="right-toggle-menu-outer">
                    <div className="right-toggle-menu">
                      <div
                        className="right-toggle-menu-inner"
                        onClick={handleRightToggle}
                      >
                        <div className="wallent">
                          {walletAddress ? (
                            <button className="btn btn-one">
                              {walletAddress.substring(0, 4)}...
                              {walletAddress.slice(-4)}
                            </button>
                          ) : (
                            <button className="btn btn-two">Connect</button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </nav>
                <div
                  className={`right-sidebar ${isOpen === true ? "active" : ""}`}
                >
                  <div className="sd-header">
                    <div className="sd-header-btn" onClick={handleRightToggle}>
                      <i className="fa fa-times"></i>
                    </div>
                  </div>
                  <div className="sd-body">
                    <div className="row">
                      <div className="col-lg-12 col-sm-12">
                        <div className="connect-card">
                          <div className="card-body">
                            <div className="card-inner">
                              <div className="wallet-ui">
                                <div className="wallet-add">
                                  <h6
                                    className={`wallet-address ${
                                      walletAddress
                                        ? "wallet-connected"
                                        : "wallet-disconnected"
                                    }`}
                                  >
                                    {walletAddress ? "Wallet Address " : ""}
                                    {walletAddress
                                      ? `${walletAddress.substring(
                                          0,
                                          4
                                        )}...${walletAddress.slice(-4)}`
                                      : "Wallet not connected"}
                                  </h6>
                                  {walletAddress ? (
                                    <Tooltip title="Copy Wallet Address" arrow>
                                      <FontAwesomeIcon
                                        icon={faCopy}
                                        className="admin-lfc-deposit-copy"
                                        onClick={copyAccountToClipboard}
                                      />
                                    </Tooltip>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="admin-lfc-deposit-balance">
                                  {connected ? (
                                    <p>Balance: {balance} SOL</p>
                                  ) : (
                                    <p>Balance: 0 SOL</p>
                                  )}
                                </div>
                                <div className="wallet-btn">
                                  {connected ? (
                                    <button
                                      className="btn"
                                      onClick={disconnectWallet}
                                    >
                                      Disconnect Wallet
                                    </button>
                                  ) : (
                                    <button
                                      className="btn connect-wallet-btn"
                                      onClick={connectWallet}
                                    >
                                      <img
                                        src={phantomIcon}
                                        width="40"
                                        height="40"
                                      />
                                      Connect Wallet
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`sidebar-overlay ${
                    isOpen === true ? "active" : ""
                  }`}
                  onClick={handleRightToggle}
                ></div>
              </div>
            </div>
            <div className="user-dashboard-inner">
              <div className="user-dashboard-tab">
                <div className="container">
                  <div className="user-dashboard-tab-inner">
                    <Tabs
                      defaultActiveKey="depositesol"
                      id="uncontrolled-tab-example"
                      className=""
                    >
                      <Tab eventKey="depositesol" title="Deposit SOL">
                        <div className="user-dash-tab-form">
                          <h3>Deposit Liquidity SOL</h3>
                          <Form.Label>
                            SOL Amount<span>*</span>
                          </Form.Label>
                          <InputGroup className="">
                            <Form.Control
                              type="number"
                              name="sol"
                              aria-label="Number input with dropdown button"
                              value={tokenBAmount}
                              onChange={(e) => {
                                setTokenBAmount(e.target.value);
                                setError("");
                              }}
                              placeholder="Solana amount"
                              required
                            />
                          </InputGroup>
                        </div>
                        {isConnected ? (
                          <div className="connect-wallet-one">
                            <Button
                              onClick={() => depositSingleTokenLiquidity(true)}
                              disabled={loading}
                            >
                              {loading ? (
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              ) : (
                                "Deposit Liquidity SOL"
                              )}
                            </Button>
                          </div>
                        ) : (
                          <div className="connect-wallet-one">
                            <button onClick={handleRightToggle}>
                              Connect Wallet
                            </button>
                          </div>
                        )}
                        <p>{error}</p>
                      </Tab>
                      <Tab eventKey="deposite LAL" title="Deposit $LAL">
                        <div className="user-dash-tab-form">
                          <h3>Deposit Liquidity $LAL</h3>
                          <Form.Label>
                            $LAL Amount<span>*</span>
                          </Form.Label>
                          <InputGroup className="">
                            <Form.Control
                              type="number"
                              name="lfc"
                              aria-label="Number input with dropdown button"
                              value={tokenAAmount}
                              onChange={(e) => {
                                setTokenAAmount(e.target.value);
                                setError("");
                              }}
                              placeholder="$LAL amount"
                              required
                            />
                          </InputGroup>
                        </div>
                        {isConnected ? (
                          <div className="connect-wallet-one">
                            <Button
                              onClick={() => depositSingleTokenLiquidity(false)}
                              disabled={loading}
                            >
                              {loading ? (
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              ) : (
                                "Deposit Liquidity $LAL"
                              )}
                            </Button>
                          </div>
                        ) : (
                          <div className="connect-wallet-one">
                            <button onClick={handleRightToggle}>
                              Connect Wallet
                            </button>
                          </div>
                        )}
                        <p>{error}</p>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default AdminLFCDeposit;
