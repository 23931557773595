import { PhantomWalletAdapter } from "@solana/wallet-adapter-phantom";
import { LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";
import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { connection } from "../../constants/constants";
import { getAssociatedTokenAddress, getAccount } from "@solana/spl-token";
import { useSelector } from "react-redux";
import axios from "axios";
import { useLocation } from "react-router-dom";

function useWallet() {
  const [isOpen, setIsOpen] = useState(false);
  const [wallet, setWallet] = useState(null);
  const [connected, setConnected] = useState(false);
  const [walletAddress, setWalletAddress] = useState("");
  const [connectedAccounts, setConnectedAccounts] = useState(new Set());
  const [balance, setBalance] = useState(0);
  const [balanceLAL, setBalanceLAL] = useState(0);
  const [isConnected, setIsConnected] = useState(null);
  const [tokenAddress, setTokenAddress] = useState(null);
  const [sortField, setSortField] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState(-1);

  const user = useSelector((state) => state.userStore.user);
  const token = user.auth;
  const role = user.role;

  const location = useLocation();

  useEffect(() => {
    if ("solana" in window && window.solana.isPhantom) {
      const provider = new PhantomWalletAdapter();
      setWallet(() => provider);
    } else {
      console.error("Wallet not detected");
    }
  }, []);

  useEffect(() => {
    async function init() {
      if (!wallet) return;

      const isWalletConnected = JSON.parse(
        localStorage["isWalletConnected"] || null
      );

      if (isWalletConnected) await connectWallet();
      else await disconnectWallet();
    }

    //  run to init the wallet
    init();
  }, [wallet]);

  useEffect(() => {
    async function fetchLALBalance() {
      if (role === "user" && location.pathname === "/user-dashboard") {
        await updateBalanceLAL();
      }
    }

    fetchLALBalance();
  }, [location, role]);

  const connectWallet = async () => {
    if (!wallet) {
      Swal.fire({
        title: "Error!",
        text: "No wallet found",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }

    try {
      await wallet.connect();

      setWalletAddress(wallet.publicKey.toString());
      setConnectedAccounts((prev) =>
        new Set(prev).add(wallet.publicKey.toString())
      );
      setIsConnected(wallet.publicKey);
      setConnected(true);
      localStorage.setItem("isWalletConnected", true);
      await updateBalance(wallet.publicKey);
      if (role === "user" && location.pathname === "/user-dashboard") {
        await updateBalanceLAL(balanceLAL);
      }
    } catch (err) {
      console.error("Failed to Connect");
    }
  };

  const resetStates = () => {
    setConnected(false);
    setWalletAddress("");
    setIsConnected(null);

    localStorage.setItem("isWalletConnected", false);
  };

  const disconnectWallet = async () => {
    if (wallet) {
      try {
        await wallet.disconnect();
        resetStates();
      } catch (error) {
        console.error("Failed to connect wallet");
      }
    } else {
      Swal.fire({
        title: "Error!",
        text: "No wallet found",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const handleAccountChange = async (newPublicKey) => {
    // console.log("Account changed to:", newPublicKey.toBase58());
    if (!connectedAccounts.has(newPublicKey)) {
      resetStates();
    } else {
      await connectWallet(newPublicKey);
    }
  };

  useEffect(() => {
    if (window.solana) {
      window.solana.on("accountChanged", handleAccountChange);
    }

    return () => {
      if (window.solana) {
        window.solana.removeListener("accountChanged", handleAccountChange);
      }
    };
  }, [handleAccountChange]);

  const updateBalance = async (publicKey) => {
    const balance = await connection.getBalance(publicKey);
    const updatedBalance = (balance / LAMPORTS_PER_SOL).toFixed(5);
    // setBalance(balance / LAMPORTS_PER_SOL);
    setBalance(updatedBalance);
  };

  const copyAccountToClipboard = () => {
    navigator.clipboard.writeText(walletAddress || "").then(
      function () {
        console.log("Copying to clipboard was successful!");
      },
      function (err) {
        Swal.fire({
          title: "Error!",
          text: "Could not copy text: ",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    );
  };

  const handleRightToggle = () => setIsOpen(!isOpen);

  const fetchLFCPair = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT_URL}/deployment/getLatestDeployedToken`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      return new PublicKey(response.data.data?.tokenContractAddress || "");
    } catch (error) {
      console.error("Failed to fetch LFC pair:", error);
      throw error;
    }
  };

  const getTokenAddressAndInstruction = async (
    mint,
    owner,
    payer,
    connection
  ) => {
    let tokenAccountAddress = await getAssociatedTokenAddress(
      mint,
      owner,
      true
    );
    const accountInfo = await connection.getAccountInfo(tokenAccountAddress);
    if (accountInfo !== null) {
      return [tokenAccountAddress, null];
    }
    return [tokenAccountAddress];
  };

  const getUserLFCBalance = async (connection, userLfcTokenAccount) => {
    try {
      const userAccountInfo = await getAccount(
        connection,
        userLfcTokenAccount,
        "confirmed"
      );
      return userAccountInfo.amount;
    } catch (error) {
      console.error("Error getting LFC balance:", error);
      throw error;
    }
  };

  const updateBalanceLAL = async () => {
    if (!wallet) return;

    const LFC_PAIR = await fetchLFCPair();
    const userLfcTokenAccount = await getTokenAddressAndInstruction(
      LFC_PAIR,
      wallet.publicKey,
      wallet.publicKey,
      connection
    );

    const userLfcBalance = await getUserLFCBalance(
      connection,
      userLfcTokenAccount[0]
    );
    const updatedLALBalance = (Number(userLfcBalance) / 1000000).toFixed(5);

    setBalanceLAL(updatedLALBalance);
  };

  const copyToClipboard = (wallet) => {
    navigator.clipboard.writeText(wallet || "").then(
      function () {
        console.log("Copying to clipboard was successful!");
      },
      function (err) {
        Swal.fire({
          title: "Error!",
          text: "Could not copy text: ",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    );
  };

  const sortHistory = (filtered) => {
    if (sortField === "serialNumber") {
      const sorted = [...filtered];
      sorted.sort((a, b) => {
        const indexA = filtered.indexOf(a);
        const indexB = filtered.indexOf(b);
        return (indexA - indexB) * sortOrder;
      });
      return sorted;
    } else if (sortField === "createdAt") {
      const sorted = [...filtered];
      sorted.sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return (dateB - dateA) * sortOrder; // descending order
      });
      return sorted;
    } else if (sortField) {
      const sorted = [...filtered];
      sorted.sort((a, b) => {
        const valueA = a[sortField];
        const valueB = b[sortField];
        if (valueA < valueB) return -1 * sortOrder;
        if (valueA > valueB) return 1 * sortOrder;
        return 0;
      });
      return sorted;
    }
    return filtered;
  };

  const onSort = (event) => {
    setSortField(event.sortField);
    setSortOrder(event.sortOrder);
  };

  return {
    isOpen,
    wallet,
    connected,
    walletAddress,
    connectedAccounts,
    balance,
    isConnected,
    tokenAddress,
    setTokenAddress,
    connectWallet,
    disconnectWallet,
    handleAccountChange,
    updateBalance,
    handleRightToggle,
    copyAccountToClipboard,
    setIsOpen,
    // updateBalanceLAL,
    balanceLAL,
    copyToClipboard,
    sortField,
    sortOrder,
    sortHistory,
    onSort,
  };
}

export default useWallet;
