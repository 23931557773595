import { useState, useEffect, useRef } from "react";
import {
  PublicKey,
  SystemProgram,
  Transaction,
  LAMPORTS_PER_SOL,
} from "@solana/web3.js";
import { useSelector } from "react-redux";
import axios from "axios";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Swal from "sweetalert2";
import SolanaPrice from "./websocket/SolanaPrice";

const SendSOL = ({ connectedWallet, connection, isOpen, setIsOpen }) => {
  const [solValue, setSolValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [tokenValue, setTokenValue] = useState("");
  const [calculated, setCalculated] = useState(false);
  const [calculatedId, setCalculatedId] = useState("");
  const [timer, setTimer] = useState(20);
  const [isTimedOut, setIsTimedOut] = useState(false);
  const [isLimit, setIsLimit] = useState(false);
  const [tokenLimit, setTokenLimit] = useState("");
  const timerInterval = useRef(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [requiredSOL, setRequiredSOL] = useState("");

  const receivingWallet = new PublicKey(process.env.REACT_APP_RECEIVING_WALLET);

  const user = useSelector((state) => state.userStore.user);
  const token = user.auth;

  const getTransactionDetails = async (signature) => {
    try {
      const transaction = await connection.getTransaction(signature, {
        commitment: "confirmed",
      });

      console.log("The signature: ", transaction);

      if (transaction) {
        const buyAtDate = new Date(transaction.blockTime * 1000);
        const walletAddress =
          transaction.transaction.message.accountKeys[0].toString();
        const fee = transaction.meta.fee;

        const presSolBalance = transaction.meta.preBalances[0];
        const postSolBalances = transaction.meta.postBalances[0];

        const payedSolanaQuantity = presSolBalance - postSolBalances - fee;

        return {
          signature,
          buyAtDate,
          walletAddress,
          fee,
          payedSolanaQuantity,
        };
      } else {
        throw new Error("Transaction not Found");
      }
    } catch (error) {
      console.error("Error fetching transactions: ", error);
      throw error;
    }
  };

  const sendTransactionDetails = async (details) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT_URL}/token/transferToken`,
        details,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log("Transaction details sent to backend successfully", response);
      const signature = response.data.TokenTrxSignature;
      Swal.fire({
        title: `${response.data.message}`,
        // text: `${response.data.message}`,
        icon: response.data.status === 200 ? "success" : "error",
        showCancelButton: true,
        cancelButtonText: "Close",
        confirmButtonText: "Check on Explorer",
      }).then((result) => {
        if (result.isConfirmed) {
          if (!signature) {
            console.error("Signature not available");
            return;
          }
          const url = `https://explorer.solana.com/tx/${signature}?cluster=devnet`;

          window.open(url, "_blank", "noopener,noreferrer");
        }
      });
    } catch (error) {
      console.error("Error sending transaction details:", error);
      Swal.fire({
        title: "Error!",
        text: `Error: ${error.message}`,
        icon: "error",
        confirmButtonText: "OK",
      });
      setLoading(false);
    }
  };

  const handleClick = async () => {
    try {
      if (tokenValue) {
        setLoading(true);
        clearInterval(timerInterval.current);

        const transaction = new Transaction();
        const transferSolInstruction = SystemProgram.transfer({
          fromPubkey: connectedWallet,
          toPubkey: receivingWallet,
          lamports: solValue * LAMPORTS_PER_SOL,
        });
        transaction.add(transferSolInstruction);

        const recentBlockhash = (await connection.getLatestBlockhash())
          .blockhash;
        transaction.recentBlockhash = recentBlockhash;
        transaction.feePayer = connectedWallet;

        const signature = await window.solana.signAndSendTransaction(
          transaction
        );
        await connection.confirmTransaction(signature.signature, "confirmed");

        const _sign = signature.signature;
        const details = await getTransactionDetails(_sign);
        details.calculateId = calculatedId;
        details.payedTokenQuantity = tokenValue;

        console.log("The details are: ", details, solValue);
        await sendTransactionDetails(details);

        console.log("The transaction was successful: ", signature);
        setLoading(false);
        setSolValue("");
        setTokenValue("");
        setCalculated(false);
      } else {
        setError("No Tokens found");
      }
    } catch (error) {
      console.error("Error sending transaction: ", error);
      Swal.fire({
        title: "Error!",
        text: "Your Transaction has been failed. Please try again",
        icon: "error",
        confirmButtonText: "OK",
      });
      setLoading(false);
      setTimer(0);
      setTokenValue("");
      setCalculated(false);
    }
  };

  const getTodayBoughtTokens = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT_URL}/token/todayTokenBuyQuantity`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response;
  };

  const fetchTokenLimit = async () => {
    try {
      const responseData = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT_URL}/token/getTokenBuyLimit`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setIsLimit(responseData.data?.data?.tokenBuyLimitStatus || false);
      setTokenLimit(responseData.data?.data?.dailyTokenBuyLimit || "");
    } catch (error) {
      console.error("Failed to fetch token limit:", error);
    }
  };

  const handleGetEstimatedTokens = async () => {
    if (solValue > 0) {
      try {
        setLoading(true);
        setIsTimedOut(false);
        setTimer(20);
        setErrorMessage("");

        const response = await axios.get(
          `${process.env.REACT_APP_API_ENDPOINT_URL}/token/calculateBuyTokenQuantity/${solValue}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setTokenValue(response.data.tokenQty);
        setCalculatedId(response.data.calculatedId);
        setCalculated(true);

        let todayBoughtTokens = 0;
        if (isLimit) {
          const todayTokensResponse = await getTodayBoughtTokens();
          todayBoughtTokens = todayTokensResponse?.data?.todayBought;
        }
        const totalTokenQuantity =
          parseFloat(response.data.tokenQty) + todayBoughtTokens;
        const remainingTokenBuyLimit = tokenLimit - todayBoughtTokens;
        console.log("remainingTokenBuyLimit", remainingTokenBuyLimit);

        if (isLimit && totalTokenQuantity > tokenLimit) {
          const response = await axios.get(
            `${process.env.REACT_APP_API_ENDPOINT_URL}/token/getRequireSolQuantity/${remainingTokenBuyLimit}`,
            { headers: { Authorization: `Bearer ${token}` } }
          );
          const requiredAPISOL = response.data?.data?.SolQuantity;
          setRequiredSOL(response.data?.data?.SolQuantity || "");

          const message =
            remainingTokenBuyLimit > 0
              ? `You can only buy ${remainingTokenBuyLimit} $LAL tokens for the day. Try entering ${requiredAPISOL} as SOL amount for purchasing ${remainingTokenBuyLimit} $LAL tokens`
              : `You have exceeded the token purchase limit for the day.`;

          setErrorMessage(message);
          clearInterval(timerInterval.current);
          setLoading(false);
          return;
        }

        let swalDisplayed = false;
        timerInterval.current = setInterval(() => {
          setTimer((prevTimer) => {
            if (prevTimer <= 1) {
              clearInterval(timerInterval.current);
              setIsTimedOut(true);
              setLoading(false);
              setTokenValue("");
              setCalculated(false);
              if (!swalDisplayed) {
                swalDisplayed = true;
                Swal.fire({
                  title: "Timeout",
                  text: "Transaction is not completed in time. Please try again.",
                  icon: "error",
                  confirmButtonText: "OK",
                });
              }
              return 0;
            }
            return prevTimer - 1;
          });
        }, 1000);
      } catch (error) {
        console.error("Failed to fetch tokens:", error);
      } finally {
        setLoading(false);
      }
    } else {
      setError("Please enter an amount more than 0.");
    }
  };

  useEffect(() => {
    fetchTokenLimit();
    return () => {
      clearInterval(timerInterval.current);
    };
  }, []);

  const handleRightToggle = () => setIsOpen(!isOpen);

  const handleInputChange = (e) => {
    const value = e.target.value;
    const decimalIndex = value.indexOf(".");
    const decimalPartLength =
      decimalIndex !== -1 ? value.length - decimalIndex - 1 : 0;
    if (decimalPartLength <= 9) {
      setSolValue(value);
      setError("");
      setTokenValue("");
      setCalculated(false);
      clearInterval(timerInterval.current);
    } else {
      setError("Note: Only 9 digits allowed after the decimal point");
    }
  };

  return (
    <>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <div className="mb-3 solana-display">
          <i className="fa fa-arrow-right arrow-price"></i>
          <h6>Current Solana Price :</h6>
          <SolanaPrice />
        </div>
        <div className="mb-3 solana-display">
          <i className="fa fa-arrow-right arrow-price"></i>
          <h6>Current $LAL Price : $0.0005 USD</h6>
        </div>
        {isLimit && (
          <div className="mb-3 solana-display">
            <i className="fa fa-arrow-right arrow-price"></i>
            <h6>$LAL Purchase Limit per day: {tokenLimit}</h6>
          </div>
        )}

        <Form.Label>
          Enter SOL Amount <span>*</span>
        </Form.Label>
        <Form.Control
          type="number"
          placeholder="Enter Sol Amount"
          value={solValue}
          onChange={handleInputChange}
        />
      </Form.Group>
      {connectedWallet ? (
        <div className="connect-wallet-one">
          {!calculated && (
            <Button onClick={handleGetEstimatedTokens} disabled={loading}>
              {loading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                "Calculate Tokens"
              )}
            </Button>
          )}
          {tokenValue && (
            <div>
              <h6>
                Calculated Token Quantity:
                {tokenValue ? ` ${tokenValue} $LAL` : `0 $LAL`}
              </h6>
              <div>
                {errorMessage && (
                  <div className="error-message">
                    <span>
                      <i className="fa fa-info-circle" aria-hidden="true"></i>
                    </span>
                    {errorMessage}
                  </div>
                )}
              </div>
              <Button
                onClick={handleClick}
                disabled={loading || isTimedOut || !!errorMessage}
              >
                {loading ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  "Buy Tokens"
                )}
              </Button>
              {isTimedOut && (
                <p style={{ color: "red" }}>Transaction has timed out!</p>
              )}

              {timer > 0 && <p>Time remaining: {timer} seconds</p>}
            </div>
          )}
          {error && <div className="error-message">{error}</div>}
        </div>
      ) : (
        <div className="connect-wallet-one">
          <button onClick={handleRightToggle}>Connect Wallet</button>
        </div>
      )}
    </>
  );
};

export default SendSOL;
