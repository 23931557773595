import React, { useState } from "react";
import NavBar from "../../../components/navbar/NavBar";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import AdminSOLDepositHistory from "./AdminSOLDepositHistory";
import AdminLFCDepositHistory from "./AdminLFCDepositHistory";
import AdminSOLWithdrawHistory from "./AdminSOLWithdrawHistory";
import AdminLFCWithdrawHistory from "./AdminLFCWithdrawHistory";

const AdminDepositWithdrawHistory = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <NavBar />
      <div className={`sidebar-sec ${isOpen === true ? "active" : ""}`}>
        <section id="content-wrapper">
          <div className="trans-tab">
            <Tabs
              defaultActiveKey="admin-sol-deposit"
              id="uncontrolled-tab-example"
              className=""
            >
              <Tab eventKey="admin-sol-deposit" title="SOL Deposit History">
                <AdminSOLDepositHistory />
              </Tab>
              <Tab eventKey="admin-lfc-deposit" title="$LAL Deposit History">
                <AdminLFCDepositHistory />
              </Tab>
              <Tab eventKey="admin-sol-withdraw" title="SOL Withdraw History">
                <AdminSOLWithdrawHistory />
              </Tab>
              <Tab eventKey="admin-lfc-withdraw" title="$LAL Withdraw History">
                <AdminLFCWithdrawHistory />
              </Tab>
            </Tabs>
          </div>
        </section>
      </div>
    </>
  );
};

export default AdminDepositWithdrawHistory;
