import React, { useState } from "react";
import NavBar from "../../../components/navbar/NavBar";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import AdminSolPurchaseHistory from "./AdminSolPurchaseHistory";
// import AdminLFCPurchaseHistory from "./AdminLFCPurchaseHistory";
// import AdminLFCToSOLPurchaseHistory from "./AdminLFCToSOLPurchaseHistory";
import AdminTokenHistory from "./token/AdminTokenHistory";
import AdminLALPurchaseHistory from "./token/AdminLALPurchaseHistory";

const AdminTransactionHistory = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <NavBar />
      <div className={`sidebar-sec ${isOpen === true ? "active" : ""}`}>
        <section id="content-wrapper">
          <h4 className="my-dash main-head">
            SOL and Token Transaction History
          </h4>
          <div className="trans-tab">
            <Tabs
              defaultActiveKey="admin-sol"
              id="uncontrolled-tab-example"
              className=""
            >
              <Tab eventKey="admin-sol" title="SOL Txn History">
                <AdminSolPurchaseHistory />
              </Tab>

              {/* <Tab eventKey="admin-sol-to-lal" title="SOL to $LAL Txn History">
                <AdminLFCPurchaseHistory />
              </Tab>
              <Tab eventKey="admin-lal-to-sol" title="$LAL to SOL Txn History">
                <AdminLFCToSOLPurchaseHistory />
              </Tab> */}
              <Tab eventKey="admin-mint-history" title="Token History">
                <AdminTokenHistory />
              </Tab>
              <Tab eventKey="admin-lal-history" title="$LAL Txn History">
                <AdminLALPurchaseHistory />
              </Tab>
            </Tabs>
          </div>
        </section>
      </div>
    </>
  );
};

export default AdminTransactionHistory;
