import React, { useState, useEffect } from "react";
import {
  validateEmail,
  validatePassword,
  validateFirstName,
  validateLastName,
  validateConfirmPassword,
  validateNoNumbersOrSpecialChars,
} from "../../utils/validation";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import lineImage from "../../assets/images/line-art-large.png";
import logoImage from "../../assets/images/logo-img.png";
import Swal from "sweetalert2";

const SignUpForm = () => {
  const navigate = useNavigate();
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorFirstName, setErrorFirstName] = useState("");
  const [errorLastName, setErrorLastName] = useState("");
  const [errorPhone, setErrorPhone] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [errorConfirmPassword, setErrorConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    const auth = localStorage.getItem("user");
    if (auth) {
      navigate("/admin-dashboard");
    }
  }, [navigate]);

  function formatPhone(value) {
    let format = "";
    var numbers = value.replace(/\D/g, ""),
      char = { 0: "+1 (", 3: ") ", 6: " - " };
    if (numbers[0] === "1") numbers = numbers.slice(1);
    format = "";
    for (var i = 0; i < numbers.length; i++) {
      format += (char[i] || "") + numbers[i];
    }
    return format;
  }

  const handlePhoneChange = (e) => {
    let value = e.target.value.replace(/\D/g, "").substring(0, 11);
    let formattedPhone = formatPhone(value);
    setPhone(formattedPhone);
    const cleanedPhone = formattedPhone.replace(/\D/g, "");
    if (cleanedPhone.length === 11) {
      setErrorPhone("");
    } else if (cleanedPhone.length > 1 && cleanedPhone.length < 11) {
      setErrorPhone("Incomplete phone number");
    } else {
      setErrorPhone("");
    }
  };

  const handleSignUp = (e) => {
    e.preventDefault();
    setLoading(true);

    if (!validateFirstName(firstname)) {
      setErrorFirstName(
        firstname.length > 0 && firstname.length < 3
          ? "First Name must be at least 3 characters long"
          : "Please enter first name"
      );
      setLoading(false);
      return;
    }
    if (!validateLastName(lastname)) {
      setErrorLastName(
        lastname.length > 0 && lastname.length < 3
          ? "Last Name must be at least 3 characters long"
          : "Please enter last name"
      );
      setLoading(false);
      return;
    }
    if (!validateEmail(email)) {
      setErrorEmail(
        email.length > 0 ? "Invalid email format" : "Please enter email"
      );
      setLoading(false);
      return;
    }

    if (!validatePassword(password)) {
      let errorMessage;
      if (password.length > 0 && password.length < 6) {
        errorMessage = "Password must be at least 6 characters long";
      } else if (password.length >= 6) {
        errorMessage =
          "Your password must contain at least one uppercase letter, one lowercase letter, one number, and one special character";
      } else {
        errorMessage = "Please enter a password";
      }
      setErrorPassword(errorMessage);
      setLoading(false);
      return;
    }

    if (!validateConfirmPassword(confirmPassword)) {
      let errorMessage;
      if (confirmPassword.length > 0 && confirmPassword.length < 6) {
        errorMessage = "Confirm Password must be at least 6 characters long";
      } else if (confirmPassword.length >= 6) {
        errorMessage =
          "Your confirm password must contain at least one uppercase letter, one lowercase letter, one number, and one special character";
      } else {
        errorMessage = "Please enter a confirm password";
      }
      setErrorConfirmPassword(errorMessage);
      setLoading(false);
      return;
    }

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      setLoading(false);
      return;
    }

    const userData = {
      firstname,
      lastname,
      phone,
      email,
      password,
    };

    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT_URL}/users/signup`, userData)
      .then((response) => {
        if (response.data.status === 200) {
          Swal.fire({
            title: "Success!",
            text: "Thank you for registering. A verification email has been sent to your email. Please open the email and click the link to verify your email address",
            icon: "success",
            showCancelButton: true,
            cancelButtonText: "Close",
            confirmButtonText: "Go to Login",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/login");
            }
          });
          setFirstName("");
          setLastName("");
          setPhone("");
          setEmail("");
          setPassword("");
          setConfirmPassword("");
          setErrorFirstName("");
          setErrorLastName("");
          setErrorPhone("");
          setErrorEmail("");
          setErrorPassword("");
          setErrorConfirmPassword("");
          setError("");
        } else {
          Swal.fire({
            title: "Error!",
            text: `${response.data.message}`,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error!",
          text: "Something Went Wrong! Please try again",
          icon: "error",
          confirmButtonText: "OK",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <>
      <div className="blockChainBg">
        <div className="target-anim">
          <img src={lineImage} alt="line" />
        </div>
        <div className="login-outer-sec">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="polygon-left-outer">
                  <div className="circle_all">
                    <div className="polygon-logo">
                      <img src={logoImage} alt="logo" />
                      <p>
                        Introducing LF Coin, the cryptocurrency for the largest
                        basketball fan community in the world. Holders can enjoy
                        exclusive Laker fan content, have the chance to win
                        merchandise, game-day giveaways, and even VIP Laker
                        tickets with special access at Crypto.com Arena.
                      </p>
                    </div>
                    <div className="circle_A"></div>
                    <div className="circle_B"></div>
                    <div className="circle_C"></div>
                    <div className="circle_D"></div>
                    <div className="circle_E"></div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="right-login-outer">
                  <div className="right-login">
                    <div className="form-2-wrapper">
                      <div className="logo">
                        <img src={logoImage} alt="logo" />
                        <div className="form-head">Registration</div>
                      </div>
                      <div className="laker-coins animate__animated animate__bounce">
                        Welcome
                        <span className="fanCoins"> To LF Coin</span>
                      </div>
                      <form onSubmit={handleSignUp}>
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-box">
                              <label htmlFor="firstName">
                                First Name<span>*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="firstName"
                                name="firstName"
                                placeholder="First Name"
                                value={firstname}
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  if (
                                    validateNoNumbersOrSpecialChars(inputValue)
                                  ) {
                                    const capitalizedValue =
                                      capitalizeFirstLetter(inputValue);
                                    setFirstName(capitalizedValue);
                                    setErrorFirstName("");
                                  } else {
                                    setErrorFirstName(
                                      "Only alphabet characters are allowed."
                                    );
                                  }
                                }}
                                maxLength={16}
                              />
                              <span className="fa-solid fa-user field-icon"></span>
                            </div>
                            {errorFirstName && (
                              <p className="text-warning">{errorFirstName}</p>
                            )}
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-box">
                              <label htmlFor="lastName">
                                Last Name<span>*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="lastName"
                                name="lastName"
                                placeholder="Last Name"
                                value={lastname}
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  if (
                                    validateNoNumbersOrSpecialChars(inputValue)
                                  ) {
                                    const capitalizedValue =
                                      capitalizeFirstLetter(inputValue);
                                    setLastName(capitalizedValue);
                                    setErrorLastName("");
                                  } else {
                                    setErrorLastName(
                                      "Only alphabet characters are allowed."
                                    );
                                  }
                                }}
                                maxLength={16}
                              />
                              <span className="fa-solid fa-user field-icon"></span>
                            </div>
                            {errorLastName && (
                              <p className="text-warning">{errorLastName}</p>
                            )}
                          </div>
                          <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="form-box">
                              <label htmlFor="email">
                                Email<span>*</span>
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                id="email"
                                name="email"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => {
                                  setEmail(e.target.value);
                                  setErrorEmail("");
                                }}
                                maxLength={40}
                              />
                              <span className="fa-solid fa-envelope field-icon"></span>
                            </div>
                            {errorEmail && (
                              <p className="text-warning">{errorEmail}</p>
                            )}
                          </div>
                          <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="form-box">
                              <label htmlFor="phone">Phone</label>
                              <input
                                type="tel"
                                className="form-control"
                                id="phone"
                                name="phone"
                                placeholder="Phone"
                                value={phone}
                                onChange={handlePhoneChange}
                                onBlur={() => {
                                  if (phone === "+1 ") setPhone("");
                                }}
                              />
                              <span className="fa-solid fa-phone field-icon"></span>
                            </div>
                            {errorPhone && (
                              <p className="text-warning">{errorPhone}</p>
                            )}
                          </div>
                          <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="form-box">
                              <label htmlFor="password">
                                Password<span>*</span>
                              </label>
                              <input
                                type={showPassword ? "text" : "password"}
                                className="form-control"
                                id="password"
                                name="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => {
                                  setPassword(e.target.value);
                                  setErrorPassword("");
                                  setError("");
                                }}
                                maxLength={12}
                              />
                              <span
                                onClick={togglePasswordVisibility}
                                className={`fa ${
                                  showPassword ? "fa-eye" : "fa-eye-slash"
                                } field-icon toggle-password`}
                              ></span>
                            </div>
                            {errorPassword && (
                              <p className="text-warning">{errorPassword}</p>
                            )}
                          </div>
                          <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="form-box">
                              <label htmlFor="confirmPassword">
                                Confirm Password<span>*</span>
                              </label>
                              <input
                                type={showConfirmPassword ? "text" : "password"}
                                className="form-control"
                                id="confirmPassword"
                                name="confirmPassword"
                                placeholder="Confirm Password"
                                value={confirmPassword}
                                onChange={(e) => {
                                  setConfirmPassword(e.target.value);
                                  setErrorConfirmPassword("");
                                  setError("");
                                }}
                                maxLength={12}
                              />
                              <span
                                onClick={toggleConfirmPasswordVisibility}
                                className={`fa ${
                                  showConfirmPassword
                                    ? "fa-eye"
                                    : "fa-eye-slash"
                                } field-icon toggle-password`}
                              ></span>
                            </div>
                          </div>
                        </div>
                        {errorConfirmPassword && (
                          <p className="text-warning">{errorConfirmPassword}</p>
                        )}
                        {error && <p className="text-warning">{error}</p>}
                        <div className="login-btn">
                          <button
                            type="submit"
                            className="btn btn-outline-secondary mt-3"
                            disabled={loading}
                          >
                            {loading ? (
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            ) : (
                              "Register"
                            )}
                          </button>
                        </div>
                      </form>
                      <div className="register-test">
                        <p>
                          Already have an account?{" "}
                          <Link to="/login">Login</Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUpForm;
