import React from "react";
import { MoonPayBuyWidget } from "@moonpay/moonpay-react";
import axios from "axios";

export default function BuyWidget({
  visible,
  walletAddress,
  email,
  userInput,
  setBuyAmount,
  setLoading,
}) {
  const auth = localStorage.getItem("user");
  const userData = JSON.parse(auth);
  const token = userData.auth;

  const handleGetSignature = async (url) => {
    try {
      const response = await fetch(
        `${
          process.env.REACT_APP_API_ENDPOINT
        }/sign-url?url=${encodeURIComponent(url)}`
      );
      if (!response.ok) {
        // setBuyAmount("");
        setLoading(false);
        console.log("Failed to get signature>>>>>>>>>>>>");
        throw new Error("Failed to get signature");
      }
      const data = await response.json();
      // setBuyAmount("");
      setLoading(false);
      console.log("data>>>>>>>>>>>>");
      return data.signature;
    } catch (error) {
      console.error("Error getting signature:", error);
      // setBuyAmount("");
      setLoading(false);
      console.log("Error getting signature>>>>>>>>>>>>");
      throw error;
    }
  };

  const handleTransactionCompleted = (transactionData) => {
    const responseData = transactionData;
    const userID = userData.userId;
    responseData.userId = userID;

    try {
      axios.post(
        `${process.env.REACT_APP_API_ENDPOINT_URL}/solana/storeBuySolana`,
        responseData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
    } catch (error) {
      console.error("Error sending data", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <MoonPayBuyWidget
        variant="overlay"
        baseCurrencyCode="usd"
        baseCurrencyAmount={userInput}
        defaultCurrencyCode="sol"
        visible={visible}
        walletAddress={walletAddress}
        email={email}
        onUrlSignatureRequested={handleGetSignature}
        onTransactionCompleted={handleTransactionCompleted}
      />
    </>
  );
}
