import React, { useState } from "react";
import { validateEmail } from "../../utils/validation";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import lineImage from "../../assets/images/line-art-large.png";
import logoImage from "../../assets/images/logo-img.png";
import Swal from "sweetalert2";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleReset = (e) => {
    e.preventDefault();
    setLoading(true);

    if (!validateEmail(email)) {
      setError(
        email.length > 0 ? "Please enter correct email" : "Please enter email"
      );
      setLoading(false);
      return;
    }

    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT_URL}/users/forgotPassword`, {
        email,
      })
      .then((response) => {
        if (response.data.status === 200) {
          Swal.fire({
            title: "Success!",
            text: "Password reset link has been sent to your email. Please check email and reset your password",
            icon: "success",
            showCancelButton: true,
            cancelButtonText: "Close",
            confirmButtonText: "Go to Login",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/login");
            }
          });
          setEmail("");
          setError("");
        } else {
          Swal.fire({
            title: "Error!",
            text: `${response.data.message}`,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error!",
          text: "Something Went Wrong! Please try again",
          icon: "error",
          confirmButtonText: "OK",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div className="blockChainBg">
        <div className="target-anim">
          <img src={lineImage} alt="line" />
        </div>
        <div className="login-outer-sec">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="polygon-left-outer">
                  <div className="circle_all">
                    <div className="polygon-logo">
                      <img src={logoImage} alt="logo" />
                      <p>
                        Introducing LF Coin, the cryptocurrency for the largest
                        basketball fan community in the world. Holders can enjoy
                        exclusive Laker fan content, have the chance to win
                        merchandise, game-day giveaways, and even VIP Laker
                        tickets with special access at Crypto.com Arena.
                      </p>
                    </div>
                    <div className="circle_A"></div>
                    <div className="circle_B"></div>
                    <div className="circle_C"></div>
                    <div className="circle_D"></div>
                    <div className="circle_E"></div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="right-login-outer">
                  <div className="right-login">
                    <div className="form-2-wrapper">
                      <div className="logo">
                        <img src="assets/images/logo-img.png" alt="logo" />
                        <div className="form-head">Forgot Password ?</div>
                      </div>
                      <div className="laker-coins animate__animated animate__bounce">
                        Welcome
                        <span className="fanCoins"> To LF Coin</span>
                      </div>
                      <form onSubmit={handleReset}>
                        <div className="form-box">
                          <label htmlFor="email">
                            Email address<span>*</span>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            value={email}
                            placeholder="Enter Your Email"
                            onChange={(e) => {
                              setEmail(e.target.value);
                              setError("");
                            }}
                            maxLength={32}
                          />
                          <span className="fa-solid fa-envelope field-icon"></span>
                        </div>
                        {error && <p className="text-warning">{error}</p>}

                        <div className="login-btn">
                          <button
                            type="submit"
                            className="btn btn-outline-secondary"
                            disabled={loading}
                          >
                            {loading ? (
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            ) : (
                              "Submit"
                            )}
                          </button>
                        </div>
                      </form>
                      <div className="register-test">
                        <p>
                          Back to <Link to="/login">Log In</Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
