import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { DateRangePicker } from "rsuite";
import { handleDownloadExcel } from "../../../../src/utils/excelHelper";
import Tooltip from "@mui/material/Tooltip";
import { useSelector } from "react-redux";

const AdminSOLWithdrawHistory = () => {
  const navigate = useNavigate();
  const [history, setHistory] = useState([]);
  const [filters, setFilters] = useState({
    createdAt: "",
    withdrawalSolana: "",
    walletAddress: "",
    startDate: null,
    endDate: null,
  });

  const user = useSelector((state) => state.userStore.user);

  const normalizeStartDate = (date) => {
    const normalizedDate = new Date(date);
    normalizedDate.setHours(0, 0, 0, 0);
    return normalizedDate;
  };

  const normalizeEndDate = (date) => {
    const normalizedDate = new Date(date);
    normalizedDate.setHours(23, 59, 59, 999);
    return normalizedDate;
  };

  useEffect(() => {
    const token = user.auth;

    const fetchWithdrawHistory = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_ENDPOINT_URL}/liquidity/getWithdrawalSolLiquidity`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setHistory(response.data.data);
      } catch (error) {
        console.error("Failed to fetch users:", error);
      }
    };

    fetchWithdrawHistory();
  }, [navigate]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
  };

  const onFilterChange = (e, field) => {
    const value = e.target.value;
    setFilters((prevFilters) => ({ ...prevFilters, [field]: value }));
  };

  const filterHistory = () => {
    return history.filter((item) => {
      const transactionDate = new Date(item.createdAt);
      let dateMatch = true;

      if (filters.startDate && filters.endDate) {
        const startDate = normalizeStartDate(filters.startDate);
        const endDate = normalizeEndDate(filters.endDate);
        dateMatch = transactionDate >= startDate && transactionDate <= endDate;
      }

      const solanaMatch = filters.withdrawalSolana
        ? Number(item.withdrawalSolana) === Number(filters.withdrawalSolana)
        : true;

      const walletMatch = item.walletAddress
        .toLowerCase()
        .includes(filters.walletAddress.toLowerCase());

      return dateMatch && solanaMatch && walletMatch;
    });
  };

  const onDateRangeChange = (range) => {
    if (range) {
      setFilters({
        ...filters,
        startDate: range[0],
        endDate: range[1],
      });
    } else {
      setFilters({
        ...filters,
        startDate: null,
        endDate: null,
      });
    }
  };

  const filteredHistory = filterHistory();

  const renderHeader = () => {
    return (
      <div className="table-search">
        <div className="row">
          <div className="col-lg-2 col-sm-12">
            <div className="table-search-inner">
              <DateRangePicker
                placeholder="Select Date Range"
                onChange={onDateRangeChange}
              />
            </div>
          </div>
          <div className="col-lg-2 col-sm-12">
            <div className="table-search-inner">
              <InputGroup className="mb-3">
                <Form.Control
                  value={filters.withdrawalSolana}
                  onChange={(e) => onFilterChange(e, "withdrawalSolana")}
                  placeholder="Search by Solana Withdrawn"
                />
                <InputGroup.Text id="basic-addon2">
                  <i className="fa fa-search"></i>
                </InputGroup.Text>
              </InputGroup>
            </div>
          </div>
          <div className="col-lg-2 col-sm-12">
            <div className="table-search-inner">
              <InputGroup className="mb-3">
                <Form.Control
                  value={filters.walletAddress}
                  onChange={(e) => onFilterChange(e, "walletAddress")}
                  placeholder="Search by Wallet Address"
                />
                <InputGroup.Text id="basic-addon2">
                  <i className="fa fa-search"></i>
                </InputGroup.Text>
              </InputGroup>
            </div>
          </div>
          <div className="col">
            <div className="table-download">
              <button
                className="btn"
                onClick={() =>
                  handleDownloadExcel(filteredHistory, "SOL_Deposit_History")
                }
              >
                <Tooltip title="Download SOL Deposit History" arrow>
                  <i className="fa fa-file-excel"></i>
                </Tooltip>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="table-box sol-purchase">
        <DataTable
          value={filteredHistory}
          paginator
          rows={10}
          dataKey="_id"
          responsiveLayout="scroll"
          header={renderHeader()}
        >
          <Column
            field="createdAt"
            header="Date of Purchase"
            body={(rowData) => formatDate(rowData.createdAt)}
            sortable
          />
          <Column field="withdrawalSolana" header="Solana Withdrawn" sortable />
          <Column field="walletAddress" header="Wallet Address" sortable />
        </DataTable>
      </div>
    </>
  );
};

export default AdminSOLWithdrawHistory;
