import React, { useState, useEffect } from "react";
import NavBar from "../../../components/navbar/NavBar";

const RoadMap = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <NavBar />
      <div className={`sidebar-sec ${isOpen === true ? "active" : ""}`}>
        <section id="content-wrapper">
          <h4 className="my-dash main-head">Our Strategic Vision</h4>
          <div>
            <div className="road-map-video">
              <video autoPlay="autoplay" controls>
                <source
                  src="https://amber-adorable-bobolink-134.mypinata.cloud/ipfs/QmW2F2EU3v3iXtDfTnYTHBwk1L3Gae1egsbQwwmCsomecZ"
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default RoadMap;
