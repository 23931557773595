import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { DateRangePicker } from "rsuite";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { handleDownloadExcel } from "../../../../src/utils/excelHelper";
import Tooltip from "@mui/material/Tooltip";
import { useSelector } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import useWallet from "../../../utils/hooks";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SolPurchaseHistory = () => {
  const { copyToClipboard, sortField, sortOrder, sortHistory, onSort } =
    useWallet();
  const navigate = useNavigate();
  const [history, setHistory] = useState([]);
  const [filters, setFilters] = useState({
    createdAt: "",
    walletAddress: "",
    startDate: null,
    endDate: null,
    quoteCurrencyAmount: "",
    totalAmount: "",
  });
  const [modalVisible, setModalVisible] = useState(false);
  const [purchaseDetails, setPurchaseDetails] = useState(null);
  const user = useSelector((state) => state.userStore.user);

  const normalizeStartDate = (date) => {
    const normalizedDate = new Date(date);
    normalizedDate.setHours(0, 0, 0, 0);
    return normalizedDate;
  };

  const normalizeEndDate = (date) => {
    const normalizedDate = new Date(date);
    normalizedDate.setHours(23, 59, 59, 999);
    return normalizedDate;
  };

  useEffect(() => {
    const token = user.auth;

    const fetchSolPurchaseHistory = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_ENDPOINT_URL}/solana/getSolanaBuyHistory`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setHistory(response.data.data);
      } catch (error) {
        console.error("Failed to fetch users:", error);
      }
    };

    fetchSolPurchaseHistory();
  }, [navigate]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
  };

  const onFilterChange = (e, field) => {
    const value = e.target.value;
    setFilters((prevFilters) => ({ ...prevFilters, [field]: value }));
  };

  const filterHistory = () => {
    return history.filter((hist) => {
      const transactionDate = new Date(hist.createdAt);
      let dateMatch = true;

      if (filters.startDate && filters.endDate) {
        const startDate = normalizeStartDate(filters.startDate);
        const endDate = normalizeEndDate(filters.endDate);
        dateMatch = transactionDate >= startDate && transactionDate <= endDate;
      }

      const walletMatch = hist.walletAddress
        .toLowerCase()
        .includes(filters.walletAddress.toLowerCase());

      const solanaMatch = filters.quoteCurrencyAmount
        ? Number(hist.quoteCurrencyAmount) ===
          Number(filters.quoteCurrencyAmount)
        : true;

      const totalAmountMatch = filters.totalAmount
        ? Number(hist.totalAmount) === Number(filters.totalAmount)
        : true;

      return dateMatch && walletMatch && solanaMatch && totalAmountMatch;
    });
  };

  const onDateRangeChange = (range) => {
    if (range) {
      setFilters({
        ...filters,
        startDate: range[0],
        endDate: range[1],
      });
    } else {
      setFilters({
        ...filters,
        startDate: null,
        endDate: null,
      });
    }
  };

  const filteredHistory = filterHistory();
  const sortedHistory = sortHistory(filteredHistory);

  const renderHeader = () => {
    return (
      <div className="table-search">
        <div className="row">
          <div className="col-lg-2 col-sm-12">
            <div className="table-search-inner mb-3">
              <DateRangePicker
                placeholder="Select Date Range"
                onChange={onDateRangeChange}
              />
            </div>
          </div>

          <div className="col-lg-2 col-sm-12">
            <div className="table-search-inner">
              <InputGroup className="mb-3">
                <Form.Control
                  value={filters.quoteCurrencyAmount}
                  onChange={(e) => onFilterChange(e, "quoteCurrencyAmount")}
                  placeholder="Search by Solana Quantity"
                />
                <InputGroup.Text id="basic-addon2">
                  <i className="fa fa-search"></i>
                </InputGroup.Text>
              </InputGroup>
            </div>
          </div>

          <div className="col-lg-2 col-sm-12">
            <div className="table-search-inner">
              <InputGroup className="mb-3">
                <Form.Control
                  value={filters.totalAmount}
                  onChange={(e) => onFilterChange(e, "totalAmount")}
                  placeholder="Search by Amount"
                />
                <InputGroup.Text id="basic-addon2">
                  <i className="fa fa-search"></i>
                </InputGroup.Text>
              </InputGroup>
            </div>
          </div>

          <div className="col-lg-2 col-sm-12">
            <div className="table-search-inner">
              <InputGroup className="mb-3">
                <Form.Control
                  value={filters.walletAddress}
                  onChange={(e) => onFilterChange(e, "walletAddress")}
                  placeholder="Search by Wallet Address"
                />
                <InputGroup.Text id="basic-addon2">
                  <i className="fa fa-search"></i>
                </InputGroup.Text>
              </InputGroup>
            </div>
          </div>
          <div className="col">
            <div className="table-download">
              <button
                className="btn"
                onClick={() =>
                  handleDownloadExcel(filteredHistory, "Solana_Transactions")
                }
              >
                <Tooltip title="Download Solana Transactions" arrow>
                  <i className="fa fa-file-excel"></i>
                </Tooltip>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const fetchPurchaseDetails = async (rowData) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT_URL}/solana/getSolanaBuyDetails/${rowData._id}`,
        {
          headers: { Authorization: `Bearer ${user.auth}` },
        }
      );
      if (response.data && response.data.data) {
        setPurchaseDetails(response.data.data);
      } else {
        console.error("No data found in response.");
      }
      setModalVisible(true);
    } catch (error) {
      console.error("Failed to fetch purchase details:", error);
    }
  };

  return (
    <>
      <div className="table-box sol-purchase">
        <DataTable
          value={sortedHistory}
          paginator
          rows={10}
          dataKey="_id"
          responsiveLayout="scroll"
          header={renderHeader()}
          onSort={onSort}
          sortField={sortField}
          sortOrder={sortOrder}
        >
          <Column
            field="serialNumber"
            header="S.No"
            body={(_, { rowIndex }) => {
              const totalRecords = sortedHistory.length;
              const globalIndex = rowIndex + 1;

              if (sortField === "serialNumber" && sortOrder === -1) {
                return totalRecords - rowIndex;
              }
              return globalIndex;
            }}
            sortable
          />
          <Column
            field="createdAt"
            header="Date"
            className="phone-nowrap"
            body={(rowData) => formatDate(rowData.createdAt)}
            sortable
          />
          <Column
            field="quoteCurrencyAmount"
            header="SOL Quantity"
            body={(rowData) => (
              <div className="copy-wallet">
                <span>
                  {rowData.quoteCurrencyAmount
                    ? `${rowData.quoteCurrencyAmount}`
                    : ""}
                </span>
              </div>
            )}
            sortable
          />
          <Column
            field="totalAmount"
            header="Amount Spent"
            body={(rowData) => (
              <div className="copy-wallet">
                <span>
                  {rowData.totalAmount ? `$${rowData.totalAmount}` : ""}
                </span>
              </div>
            )}
            sortable
          />
          <Column
            field="moonpayTransactionId"
            header="MoonPay Txn ID"
            body={(rowData) => (
              <div className="copy-wallet">
                <span>
                  {rowData.moonpayTransactionId
                    ? `${rowData.moonpayTransactionId.substring(
                        0,
                        4
                      )}...${rowData.moonpayTransactionId.slice(-4)}`
                    : ""}
                </span>
                <Tooltip title="Copy Txn Id" arrow>
                  <FontAwesomeIcon
                    icon={faCopy}
                    className="copy-wallet-icon"
                    onClick={() =>
                      copyToClipboard(rowData.moonpayTransactionId)
                    }
                  />
                </Tooltip>
              </div>
            )}
            sortable
          />
          <Column
            field="walletAddress"
            header="Wallet Address"
            sortable
            body={(rowData) => (
              <div className="copy-wallet">
                <span>
                  {rowData.walletAddress
                    ? `${rowData.walletAddress.substring(
                        0,
                        4
                      )}...${rowData.walletAddress.slice(-4)}`
                    : ""}
                </span>
                <Tooltip title="Copy Wallet Address" arrow>
                  <FontAwesomeIcon
                    icon={faCopy}
                    className="copy-wallet-icon"
                    onClick={() => copyToClipboard(rowData.walletAddress)}
                  />
                </Tooltip>
              </div>
            )}
          />
          <Column
            field="status"
            header="Status"
            sortable
            body={(rowData) => (
              <div className="copy-wallet">
                <span
                  className={
                    rowData.status === "completed"
                      ? "status-success"
                      : "status-failed"
                  }
                >
                  {rowData.status === "completed" ? "Success" : "Failed"}
                </span>
              </div>
            )}
          />
          <Column
            header="Actions"
            body={(rowData) => (
              <Button
                variant="primary"
                onClick={() => fetchPurchaseDetails(rowData)}
              >
                View Details
              </Button>
            )}
          />
        </DataTable>
      </div>

      <Modal
        size="lg"
        show={modalVisible}
        onHide={() => setModalVisible(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Purchase Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {purchaseDetails ? (
            <div className="trans-content">
              <div className="trans-content-inner">
                <p>Purchase Date:</p>
                <span> {formatDate(purchaseDetails.createdAt)} </span>
              </div>
              <div className="trans-content-inner">
                <p>Solana Quantity:</p>
                <span>{purchaseDetails.quoteCurrencyAmount}</span>
              </div>
              <div className="trans-content-inner">
                <p>Total Amount:</p>
                <span>
                  {purchaseDetails.totalAmount
                    ? `$${purchaseDetails.totalAmount}`
                    : ""}
                </span>
              </div>
              <div className="trans-content-inner">
                <p>Amount Spent:</p>
                <span>
                  {purchaseDetails.baseCurrencyAmount
                    ? `$${purchaseDetails.baseCurrencyAmount}`
                    : ""}
                </span>
              </div>
              <div className="trans-content-inner">
                <p>Fee Amount:</p>
                <span>
                  {purchaseDetails.feeAmount
                    ? `$${purchaseDetails.feeAmount}`
                    : ""}
                </span>
              </div>
              <div className="trans-content-inner">
                <p>Network Fee Amount:</p>
                <span>
                  {purchaseDetails.networkFeeAmount
                    ? `$${purchaseDetails.networkFeeAmount}`
                    : ""}
                </span>
              </div>

              <div className="copy-wallet">
                <div className="trans-content-inner">
                  <p>MoonPay Txn Id:</p>
                  <span>
                    {purchaseDetails.moonpayTransactionId
                      ? `${purchaseDetails.moonpayTransactionId.substring(
                          0,
                          4
                        )}...${purchaseDetails.moonpayTransactionId.slice(-4)}`
                      : ""}
                  </span>
                  {purchaseDetails.moonpayTransactionId ? (
                    <Tooltip title="Copy Txn Id" arrow>
                      <FontAwesomeIcon
                        icon={faCopy}
                        className="copy-wallet-icon"
                        onClick={() =>
                          copyToClipboard(purchaseDetails.moonpayTransactionId)
                        }
                      />
                    </Tooltip>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="copy-wallet">
                <div className="trans-content-inner">
                  <p>Wallet Address:</p>
                  <span>
                    {purchaseDetails.walletAddress
                      ? `${purchaseDetails.walletAddress.substring(
                          0,
                          4
                        )}...${purchaseDetails.walletAddress.slice(-4)}`
                      : ""}
                  </span>
                  {purchaseDetails.walletAddress ? (
                    <Tooltip title="Copy Wallet Address" arrow>
                      <FontAwesomeIcon
                        icon={faCopy}
                        className="copy-wallet-icon"
                        onClick={() =>
                          copyToClipboard(purchaseDetails.walletAddress)
                        }
                      />
                    </Tooltip>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="copy-wallet">
                <div className="trans-content-inner">
                  <p>Transaction Status:</p>
                  <span>
                    {purchaseDetails.status === "completed"
                      ? "Success"
                      : "Failed"}
                  </span>
                </div>
              </div>
              <div className="trans-content-inner">
                <p>Purchased Currency Name:</p>
                <span> {purchaseDetails.quoteCurrencyName}</span>
              </div>
              <div className="trans-content-inner">
                <p>Spent Currency Name:</p>
                <span> {purchaseDetails.baseCurrencyName}</span>
              </div>
            </div>
          ) : (
            <p>Loading purchase details...</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalVisible(false)}>
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SolPurchaseHistory;
