import React, { useEffect, useState } from "react";
import TokenMinter from "../../../../components/createToken";
import NavBar from "../../../../components/navbar/NavBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import Tooltip from "@mui/material/Tooltip";
import phantomIcon from "../../../../assets/images/phantom.png";
import { PublicKey } from "@solana/web3.js";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import TokenMintingForm from "../../../../components/mint";
import axios from "axios";
import useWallet from "../../../../utils/hooks";
import { useSelector } from "react-redux";

const AdminToken = () => {
  const {
    isOpen,
    wallet,
    connected,
    walletAddress,
    tokenAddress,
    balance,
    isConnected,
    setTokenAddress,
    connectWallet,
    disconnectWallet,
    handleRightToggle,
    copyAccountToClipboard,
    setIsOpen,
  } = useWallet();
  const [currentToken, setCurrentToken] = useState(null);

  const user = useSelector((state) => state.userStore.user);
  const token = user.auth;

  const fetchLatestDeployedToken = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT_URL}/deployment/getLatestTokenDepolyment`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const latestTokenAddress =
        response.data.data[0]?.tokenContractAddress || "";
      setTokenAddress(latestTokenAddress);
    } catch (error) {
      console.error("Failed to fetch token:", error);
      setTokenAddress("");
    }
  };

  const fetchLatestDeployedAndMintedToken = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT_URL}/deployment/getLatestDeployedTokenAdmin`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const latestDeployedTokenAddress =
        response.data.data?.tokenContractAddress || "";
      setCurrentToken(latestDeployedTokenAddress);
    } catch (error) {
      console.error("Failed to fetch current token:", error);
      setCurrentToken("");
    }
  };

  useEffect(() => {
    fetchLatestDeployedAndMintedToken();
  }, []);

  const handleTabChange = (key) => {
    if (key === "minttoken") {
      fetchLatestDeployedToken();
    } else if (key === "createtoken") {
      fetchLatestDeployedAndMintedToken();
    }
  };

  return (
    <>
      <NavBar />
      <div className={`sidebar-sec ${isOpen === true ? "active" : ""}`}>
        <section id="content-wrapper">
          <h4 className="my-dash main-head">Token Management</h4>
          <div className="running-token">
            {currentToken ? (
              <h5>
                Current Running $LAL Token Address : <span>{currentToken}</span>
              </h5>
            ) : (
              ""
            )}
          </div>
          <div className="user-dashboard">
            <div className="right-sidebar">
              <div className="right-sidebar-inner">
                <nav className="navbar navbar-expand-lg navbar-light">
                  <div className="right-toggle-menu-outer">
                    <div className="right-toggle-menu">
                      <div
                        className="right-toggle-menu-inner"
                        onClick={handleRightToggle}
                      >
                        <div className="wallent">
                          {walletAddress ? (
                            <button className="btn btn-one">
                              {walletAddress.substring(0, 4)}...
                              {walletAddress.slice(-4)}
                            </button>
                          ) : (
                            <button className="btn btn-two">Connect</button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </nav>
                <div
                  className={`right-sidebar ${isOpen === true ? "active" : ""}`}
                >
                  <div className="sd-header">
                    <div className="sd-header-btn" onClick={handleRightToggle}>
                      <i className="fa fa-times"></i>
                    </div>
                  </div>
                  <div className="sd-body">
                    <div className="row">
                      <div className="col-lg-12 col-sm-12">
                        <div className="connect-card">
                          <div className="card-body">
                            <div className="card-inner">
                              <div className="wallet-ui">
                                <div className="wallet-add">
                                  <h6
                                    className={`wallet-address ${
                                      walletAddress
                                        ? "wallet-connected"
                                        : "wallet-disconnected"
                                    }`}
                                  >
                                    {walletAddress ? "Wallet Address " : ""}
                                    {walletAddress
                                      ? `${walletAddress.substring(
                                          0,
                                          4
                                        )}...${walletAddress.slice(-4)}`
                                      : "Wallet not connected"}
                                  </h6>
                                  {walletAddress ? (
                                    <Tooltip title="Copy Wallet Address" arrow>
                                      <FontAwesomeIcon
                                        icon={faCopy}
                                        className="admin-lfc-deposit-copy"
                                        onClick={copyAccountToClipboard}
                                      />
                                    </Tooltip>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="admin-lfc-deposit-balance">
                                  {connected ? (
                                    <p>Balance: {balance} SOL</p>
                                  ) : (
                                    <p>Balance: 0 SOL</p>
                                  )}
                                </div>
                                <div className="wallet-btn">
                                  {connected ? (
                                    <button
                                      className="btn"
                                      onClick={disconnectWallet}
                                    >
                                      Disconnect Wallet
                                    </button>
                                  ) : (
                                    <button
                                      className="btn connect-wallet-btn"
                                      onClick={connectWallet}
                                    >
                                      <img
                                        src={phantomIcon}
                                        width="40"
                                        height="40"
                                      />
                                      Connect Wallet
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`sidebar-overlay ${
                    isOpen === true ? "active" : ""
                  }`}
                  onClick={handleRightToggle}
                ></div>
              </div>
            </div>
            <div className="user-dashboard-inner">
              <div className="user-dashboard-tab">
                <div className="container">
                  <div className="user-dashboard-tab-inner">
                    <Tabs
                      defaultActiveKey="createtoken"
                      id="uncontrolled-tab-example"
                      onSelect={handleTabChange}
                      className=""
                    >
                      <Tab eventKey="createtoken" title="Create Token">
                        <div className="user-dash-tab-form">
                          <TokenMinter
                            wallet={wallet}
                            isConnected={isConnected}
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                          />
                        </div>
                      </Tab>
                      <Tab eventKey="minttoken" title="Mint Tokens">
                        <div className="user-dash-tab-form">
                          <TokenMintingForm
                            payer={isConnected}
                            tokenMint={
                              tokenAddress ? new PublicKey(tokenAddress) : ""
                            }
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                          />
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default AdminToken;
