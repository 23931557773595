import React, { useState, useEffect } from "react";
import { PublicKey, Transaction } from "@solana/web3.js";
import {
  createMintToInstruction,
  getAssociatedTokenAddress,
  createAssociatedTokenAccountInstruction,
  AuthorityType,
  createSetAuthorityInstruction,
} from "@solana/spl-token";
import { connection } from "../constants/constants";
import * as buffer from "buffer";
import Swal from "sweetalert2";
import axios from "axios";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import { useSelector } from "react-redux";

window.Buffer = buffer.Buffer;

const getTokenAddressAndInstruction = async (
  mint,
  owner,
  payer,
  connection
) => {
  let tokenAccountAddress = await getAssociatedTokenAddress(mint, owner, true);
  const accountInfo = await connection.getAccountInfo(tokenAccountAddress);
  if (accountInfo !== null) {
    return [tokenAccountAddress, null];
  }
  let tokenAccountCreationInstruction = createAssociatedTokenAccountInstruction(
    payer,
    tokenAccountAddress,
    owner,
    mint
  );
  return [tokenAccountAddress, tokenAccountCreationInstruction];
};

const TokenMintingForm = ({ payer, tokenMint, isOpen, setIsOpen }) => {
  const [treasuryAccount, setTreasuryAccount] = useState("");
  const [devSharesAccount, setDevSharesAccount] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const user = useSelector((state) => state.userStore.user);

  const token = user.auth;

  const TOKEN_DECIMALS = 1000000;
  const TOTAL_SUPPLY = 1000000000 * TOKEN_DECIMALS;
  const DEV_SHARES = 300000000 * TOKEN_DECIMALS;
  const TREASURY_AMOUNT = TOTAL_SUPPLY - DEV_SHARES;

  useEffect(() => {
    if (payer) {
      console.log("Payer address:", payer.toBase58());
    }
  }, [payer]);

  const handleMint = async (e) => {
    e.preventDefault();
    if (treasuryAccount && devSharesAccount) {
      try {
        if (!payer || !connection || !tokenMint) {
          throw new Error("Payer, connection, or token mint not initialized");
        }
        setLoading(true);

        const treasuryPubKey = new PublicKey(treasuryAccount);
        const devSharesPubKey = new PublicKey(devSharesAccount);

        console.log("Token's mint address:", tokenMint.toBase58());

        // Create a new transaction
        const transaction = new Transaction();

        const [treasuryTokenAccount, treasuryTokenAccountInstructoin] =
          await getTokenAddressAndInstruction(
            tokenMint,
            treasuryPubKey,
            payer,
            connection
          );

        // If a new account was created, add the creation instruction to the transaction
        if (treasuryTokenAccountInstructoin) {
          transaction.add(treasuryTokenAccountInstructoin);
        }

        const [devSharesTokenAccount, devSharesTokenInstruction] =
          await getTokenAddressAndInstruction(
            tokenMint,
            devSharesPubKey,
            payer,
            connection
          );

        // If a new account was created, add the creation instruction to the transaction
        if (devSharesTokenInstruction) {
          transaction.add(devSharesTokenInstruction);
        }

        // Create mint instruction for treasury
        const mintToTreasuryInstruction = createMintToInstruction(
          tokenMint,
          treasuryTokenAccount,
          payer,
          TREASURY_AMOUNT
        );

        // Create mint instruction for dev shares
        const mintToDevSharesInstruction = createMintToInstruction(
          tokenMint,
          devSharesTokenAccount,
          payer,
          DEV_SHARES
        );

        // Add mint instructions to the transaction
        transaction.add(mintToTreasuryInstruction, mintToDevSharesInstruction);

        const setAuthorityInstruction = createSetAuthorityInstruction(
          tokenMint,
          payer,
          AuthorityType.MintTokens,
          null,
          []
        );

        transaction.add(setAuthorityInstruction);

        // Set recent blockhash and fee payer
        transaction.recentBlockhash = (
          await connection.getLatestBlockhash()
        ).blockhash;
        transaction.feePayer = payer;

        console.log(
          "The address are: ",
          payer,
          treasuryTokenAccount,
          devSharesTokenAccount
        );
        console.log("payer", payer.toBase58());
        console.log("treasuryTokenAccount", treasuryTokenAccount.toBase58());
        console.log("devSharesTokenAccount", devSharesTokenAccount.toBase58());

        const signature = await window.solana.signAndSendTransaction(
          transaction
        );
        await connection.confirmTransaction(signature);

        console.log("Signature", signature);
        console.log(
          `https://explorer.solana.com/tx/${signature}?cluster=devnet`
        );

        const _sign = signature.signature;

        const details = await getTransactionDetails(
          _sign,
          payer,
          treasuryTokenAccount,
          devSharesTokenAccount,
          tokenMint
        );

        console.log("The details are: ", details);

        await sendTransactionDetails(details);

        Swal.fire({
          title: "Success!",
          text: "Token minting completed successfully!",
          icon: "success",
          showCancelButton: true,
          cancelButtonText: "Close",
          confirmButtonText: "Check on Explorer",
        }).then((result) => {
          if (result.isConfirmed) {
            if (!_sign) {
              console.error("Signature not available");
              return;
            }
            const url = `https://explorer.solana.com/tx/${_sign}?cluster=devnet`;

            window.open(url, "_blank", "noopener,noreferrer");
          }
        });
        setLoading(false);
        setTreasuryAccount("");
        setDevSharesAccount("");
      } catch (error) {
        console.error("Error minting tokens:", error);
        Swal.fire({
          title: "Error!",
          text: "Transaction failed. Please try again.",
          icon: "error",
          confirmButtonText: "OK",
        });
        setLoading(false);
      }
    } else {
      setError("Please enter public keys to proceed");
    }
  };

  const getTransactionStatus = async (signature) => {
    try {
      const status = await connection.getSignatureStatus(signature);

      if (status?.value?.err === null) {
        console.log("Transaction completed successfully");
        return "Success";
      } else {
        console.log("Transaction failed with error: ", status?.value?.err);
        return "Failure";
      }
    } catch (err) {
      console.log("Transaction failed");
    }
  };

  const getTransactionDetails = async (
    signature,
    payer,
    treasuryTokenAccount,
    devSharesTokenAccount,
    tokenMint
  ) => {
    try {
      const mintwalletAddress = payer.toBase58();
      const treasuryWallet = treasuryTokenAccount.toBase58();
      const devWallet = devSharesTokenAccount.toBase58();
      const tokenContractAddress = tokenMint.toBase58();
      const status = await getTransactionStatus(signature);

      return {
        signature,
        mintwalletAddress,
        treasuryWallet,
        devWallet,
        tokenContractAddress,
        status,
      };
    } catch (error) {
      console.error("Error fetching transactions: ", error);
      throw error;
    }
  };

  const sendTransactionDetails = async (details) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT_URL}/deployment/mintToken`,
        details,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log("mint details sent to backend successfully", details);
    } catch (error) {
      console.error("Error sending transaction details:", error);
    }
  };

  const handleConnectWallet = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <h3>Token Minting</h3>
      <div className="user-dash-tab-form">
        <Form.Label>
          Treasury Wallet Address<span>*</span>
        </Form.Label>
        <InputGroup className="">
          <Form.Control
            type="text"
            name="treasuryAccount"
            aria-label="Number input with dropdown button"
            value={treasuryAccount}
            onChange={(e) => {
              setTreasuryAccount(e.target.value);
              setError("");
            }}
            placeholder="Treasury Wallet Address"
            required
          />
        </InputGroup>
      </div>
      <div className="user-dash-tab-form">
        <Form.Label>
          Dev Wallet Address<span>*</span>
        </Form.Label>
        <InputGroup className="">
          <Form.Control
            type="text"
            name="treasuryAccount"
            aria-label="Number input with dropdown button"
            value={devSharesAccount}
            onChange={(e) => {
              setDevSharesAccount(e.target.value);
              setError("");
            }}
            placeholder="Dev Wallet Address"
            required
          />
        </InputGroup>
      </div>
      {payer ? (
        <div className="connect-wallet-one">
          <Button onClick={handleMint} disabled={loading}>
            {loading ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              "Mint Tokens"
            )}
          </Button>
        </div>
      ) : (
        <div className="connect-wallet-one">
          <button onClick={handleConnectWallet}>Connect Wallet</button>
        </div>
      )}
      <p>{error}</p>
    </div>
  );
};

export default TokenMintingForm;
