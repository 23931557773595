import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import NavBar from "../../../../components/navbar/NavBar";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Switch from "react-bootstrap/Switch";
import Swal from "sweetalert2";

const AdminSettings = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [inputToken, setInputToken] = useState("");
  const [savedToken, setSavedToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [isLimit, setIsLimit] = useState(false);
  const [error, setError] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const user = useSelector((state) => state.userStore.user);
  const token = user.auth;

  const handleToggle = () => {
    setIsLimit(!isLimit);
    setShowMessage(true);
    console.log("!isLimit", !isLimit);
  };

  const handleSetLimit = async () => {
    if (!inputToken) {
      setError("Please enter token limit quantity.");
      return;
    }
    setLoading(true);
    setError("");
    setShowMessage(false);

    const details = {
      tokenQuantity: inputToken,
      status: isLimit,
    };
    console.log("details", details);
    try {
      await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT_URL}/token/setTokenBuyLimit`,
        details,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log("Token limit sent to backend successfully", inputToken);
      setSavedToken(inputToken);
      const message = isLimit
        ? `You have successfully set the daily token purchase limit to ${inputToken} $LAL per user.`
        : "You have successfully unset the daily token purchase limit.";

      Swal.fire({
        title: "Success!",
        text: `${message}`,
        icon: "success",
        confirmButtonText: "OK",
      });
    } catch (error) {
      console.error("Error sending token limit details:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchLimit = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_ENDPOINT_URL}/token/getAdminTokenBuyLimit`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setIsLimit(response.data?.data?.tokenBuyLimitStatus || false);
        setInputToken(response.data?.data?.dailyTokenBuyLimit || "");
        setSavedToken(response.data?.data?.dailyTokenBuyLimit || "");
      } catch (error) {
        console.error("Failed to fetch token limit:", error);
      }
    };

    fetchLimit();
  }, [navigate]);

  return (
    <>
      <NavBar />
      <div className={`sidebar-sec ${isOpen === true ? "active" : ""}`}>
        <section id="content-wrapper">
          <h4 className="my-dash main-head">Configure Purchase Limits</h4>
          <div className="user-dashboard-inner">
            <div className="user-dashboard-tab">
              <div className="container">
                <div className="user-dashboard-tab-inner">
                  <Tabs
                    defaultActiveKey="limit"
                    id="uncontrolled-tab-example"
                    className=""
                  >
                    <Tab eventKey="limit" title="Purchase Limit">
                      <div className="user-dash-tab-form">
                        <div className="token-limit-display mb-2">
                          {savedToken ? (
                            <div className="mb-3 solana-display">
                              <i className="fa fa-arrow-right arrow-price"></i>
                              <h6>
                                Daily Token Purchase limit per user:
                                {savedToken} $LAL
                              </h6>
                            </div>
                          ) : (
                            <div className="mb-3 solana-display">
                              <i className="fa fa-arrow-right arrow-price"></i>
                              <h6>
                                Buy Token limit is not set currently. You can
                                set it below
                              </h6>
                            </div>
                          )}
                        </div>
                        <Form.Label>
                          Set Token Purchase Limit<span>*</span>
                        </Form.Label>
                        <InputGroup className="">
                          <Form.Control
                            type="number"
                            name="token"
                            aria-label="Number input with dropdown button"
                            value={inputToken}
                            onChange={(e) => {
                              setInputToken(e.target.value);
                              setError("");
                            }}
                            placeholder="Enter Token Amount"
                            required
                          />
                        </InputGroup>
                      </div>
                      <div className="mb-2">
                        {error && <div className="error">{error}</div>}
                      </div>
                      <div className="connect-wallet-one">
                        <Switch
                          checked={isLimit}
                          onChange={handleToggle}
                          label="Enable/Disable Token Purchase Limit"
                        />
                      </div>
                      {showMessage && (
                        <span className="admin-setting-text">
                          You are about to
                          {isLimit
                            ? " enable token limit. "
                            : " disable token limit. "}
                          To apply this change, please click on Submit
                        </span>
                      )}
                      <div className="connect-wallet-one">
                        <Button onClick={handleSetLimit} disabled={loading}>
                          {loading ? (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            "Submit"
                          )}
                        </Button>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default AdminSettings;
