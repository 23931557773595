import React, { useState } from "react";
import NavBar from "../../../components/navbar/NavBar";
import { Timeline } from "react-twitter-widgets";
import Spinner from "react-bootstrap/Spinner";
import logoImage from "../../../assets/images/logo-img.png";

const Community = () => {
  const [isOpen, setIsOpen] = useState(false);
  const userNames = [
    "lfcoindao",
    "goldenknightgfx",
    "lakeshowyo",
    "thepettiestla",
    "danthelakersfan",
  ];
  const [loadingStates, setLoadingStates] = useState(
    Array(userNames.length).fill(true)
  );

  const handleLoad = (index) => {
    setLoadingStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = false;
      return newStates;
    });
  };

  return (
    <div>
      <NavBar />
      <div className={`sidebar-sec ${isOpen ? "active" : ""}`}>
        <section id="content-wrapper">
          <h4 className="my-dash main-head">Join the Community</h4>
          <div className="container-fluid">
            <div className="community-outer-section">
              <div className="row">
                {userNames.map((userName, index) => (
                  <div key={index} className="col-lg-4 col-md-6 col-sm-12">
                    <div className="community-card">
                      {loadingStates[index] && (
                        <div className="spinner-container">
                          <Spinner
                            animation="border"
                            variant="dark"
                            size="sm"
                          />
                        </div>
                      )}
                      <Timeline
                        dataSource={{
                          sourceType: "profile",
                          screenName: userName,
                        }}
                        onLoad={() => handleLoad(index)}
                      />
                    </div>
                  </div>
                ))}
                <div className="col-lg-4 col-md-6 col-sm-12 text-center">
                  <img src={logoImage} alt="Logo" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Community;
