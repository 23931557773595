import React, { useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

const VerifyAccount = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = window.location.pathname.split("/").pop();

    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        if (decodedToken.exp < currentTime) {
          Swal.fire({
            title: "Error!",
            text: "Token expired. Please request a new one.",
            icon: "error",
            confirmButtonText: "OK",
          });
        } else {
          axios
            .post(
              `${process.env.REACT_APP_API_ENDPOINT_URL}/users/mailVerifaction:token`,
              {
                token: token,
              }
            )
            .then((response) => {
              if (response.data.status === 200) {
                Swal.fire({
                  title: "Success!",
                  text: "Your email verification is successful! Please login to continue",
                  icon: "success",
                  showCancelButton: true,
                  cancelButtonText: "Close",
                  confirmButtonText: "Go to Login",
                }).then((result) => {
                  if (result.isConfirmed) {
                    navigate("/login");
                  }
                });
              } else {
                Swal.fire({
                  title: "Error!",
                  text: `${response.data.message}`,
                  icon: "error",
                  confirmButtonText: "OK",
                });
              }
            })
            .catch((error) => {
              Swal.fire({
                title: "Error!",
                text: "Something Went Wrong! Please try again",
                icon: "error",
                confirmButtonText: "OK",
              });
            });
        }
      } catch (error) {
        Swal.fire({
          title: "Error!",
          text: "Something Went Wrong! Please try again",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } else {
      Swal.fire({
        title: "Error!",
        text: "No token found in URL, please try again",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  }, [navigate]);

  return <></>;
};

export default VerifyAccount;
