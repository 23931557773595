import Content from "./Content";
import Head from "./Head";

export default function Main() {
  return (
    <div className="main-body-sec">
      <Head />
      <Content />
    </div>
  );
}
